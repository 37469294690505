h3 {
  position: sticky;
  top: 0;
  z-index: 11;
  display: inline-block;
  background-color: white;
  width: 100%;
}
.saleslist {
  overflow: auto;
  height: 100%;
}
.saleslisttitle {
  font-family: "Roboto", sans-serif;
  color: rgb(68, 65, 162);
  /* padding-bottom: 1rem; */
}
.refreshicon {
  height: 25px;
  cursor: pointer;
}
.salestitleline {
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
}
.clearfilterbutton {
  border-radius: 1.5rem;
  border: 1px solid rgb(68, 65, 162);
  margin-right: 1rem;
  width: 200px;
  color: rgb(68, 65, 162);
  background-color: white;
}
.clearfilterbutton:hover {
  cursor: pointer;
  color: white;
  background-color: rgb(68, 65, 162);
}
