.analysiscontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.analysisnav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 19%;
  width: 230px;
  background-color: rgb(65, 64, 73);
  /* background-color: black; */
  color: white;
  padding: 8rem 2rem;
  height: 100vh;
}
.analysisnav a {
  font-size: 0.9rem;
  color: white;
}
.analysisgridcontainer {
  /* margin-left: 10%; */
  display: flex;
  flex-basis: 90%;
  display: inline-block;
  width: 100%;
  position: relative;
  left: 230px;
}
.lysaleslist {
  overflow: auto;
  height: 90%;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  padding-bottom: 5rem;
}
.lystitlesection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lystitle {
  margin-bottom: 1rem;
}
.lysalesline {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lysalescolumn {
  display: flex;
  flex-basis: 20%;
}
.lysfig {
  text-align: right;
  width: 100px;
  display: inline-block;
  padding-right: 3rem;
}
.lysalesheader {
  position: sticky;
  top: 0;
  font-weight: bold;
  border-bottom: 1px solid rgb(68, 65, 162);
  margin-bottom: 0.5rem;
  background-color: white;
  /* color: red; */
}
.lysalesmonth {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  /* margin-top: 0.5rem; */
  top: 19px;
}
.lysalesdata li:nth-child(even) {
  background-color: rgb(245, 245, 245);
}
.lysalesline:hover {
  background-color: #ddeaf9;
}
.lysalesdata li:nth-child(even):hover {
  background-color: #ddeaf9;
}
