.adminpage {
  padding-top: 6.5rem;
  /* padding-left: 3rem; */
  display: flex;
  flex-direction: row;
  /* height: 100vh; */
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}

.adminnav {
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  /* background-color: yellow; */
  padding: 0;
  /* margin-top: 0; */
}
.adminnav button {
  width: 100%;
  margin: 0;
  padding: 1rem;
  border: gray dashed 1px;
}
.displayblock {
  display: block;
}
.displaynone {
  display: none;
}

.adminresults {
  display: flex;
  flex-basis: 55%;
  background-color: white;
  padding: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-flow: row wrap;
  justify-content: flex-start;
  height: 90vh;

  /* flex-flow: wrap; */
}
.adminedit {
  display: flex;
  flex-basis: 33%;
  padding: 2rem;
  padding-left: 4rem;
  flex-direction: column;
}
.editCustomer {
  margin-bottom: 1rem;
}

.editTrader fieldset,
.editCustomer fieldset,
.editProdGroup fieldset,
.editProdCatName fieldset,
.editProdName fieldset {
  border: 1px solid black;
  border-style: groove;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 1rem;
}
.editTrader fieldset legend,
.editCustomer fieldset legend,
.editProdGroup fieldset legend,
.editProdCatName fieldset legend,
.editProdName fieldset legend {
  font-weight: bold;
  font-size: 1rem;
}
.traderlistresults,
.customerlistresults {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 85vh;
  overflow-x: hidden;
  /* margin: 0; */
}
.tradercard,
.customercard {
  display: flex;
  flex-direction: column;
  /* flex-basis: 16%; */
  /* width: 300px; */
  flex-basis: 30%;
  min-width: 293px;
  background-color: #ededed;
  height: 150px;
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  transition: transform 0.2s ease;
  overflow-x: hidden;
  /* margin: 1rem; */
}
.customercard:active:hover,
.tradercard:active:hover {
  background-color: rgb(207, 207, 207);
}
.tradercard h4 {
  border-bottom: dashed 1px black;
}
.customercard {
  font-size: 0.85rem;
  height: 170px;
}

&:nth-child(6n-1) {
  margin-left: 2%;
  margin-right: 2%;
}
.tradercard:hover,
.customercard:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: rgb(226, 226, 226);
}

.traderemail {
  font-size: 0.8rem;
  padding: 1rem 0;
}
.traderrole {
  font-size: 0.8rem;
}
.edittrader-form-group,
.editcustomer-form-group,
.editprodgroup-form-group,
.editprodcatname-form-group,
.editprodname-form-group {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}
.edittrader-form-group label {
  display: flex;
  flex-basis: 20%;
  padding: 0.5rem 0;
}
.editcustomer-form-group label,
.editprodgroup-form-group label,
.editprodcatname-form-group label,
.editprodname-form-group label {
  display: flex;
  flex-basis: 25%;
  padding: 0.5rem 0;
}
.edittrader-form-group input,
.edittrader-form-group select {
  display: flex;
  flex-basis: 75%;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
}
.editcustomer-form-group input,
.editcustomer-form-group textarea,
.editprodgroup-form-group input,
.editprodcatname-form-group input,
.editprodname-form-group input,
.prodnamesearch {
  display: flex;
  flex-basis: 70%;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.prodnamesearchv {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
}
.editcustomer-form-group textarea {
  height: 100px;
}
.edittrader-form-group .activeradio {
  flex-basis: 20%;
  margin: 0.5rem 0;
}
.editTrader button,
.editCustomer button,
.editProdGroup button,
.editProdCatName button,
.editProdName button {
  margin-top: 1.5rem;
  width: 95%;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: rgb(68, 65, 162);
  border: none;
  color: white;
}
.editTrader button:hover,
.editCustomer button:hover,
.editProdGroup button:hover,
.editProdCatName button:hover,
.editProdName button:hover {
  background-color: rgb(123, 120, 219);
  cursor: pointer;
}

.customercountry {
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
}
.customercard ul {
  margin-top: 0.5rem;
}
.customercard ul li {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
}
.addresslabel {
  display: flex;
  flex-basis: 24%;
  font-size: 0.8rem;
  line-height: 0.9rem;
}
.addressfield {
  display: flex;
  flex-basis: 76%;
  font-size: 0.6rem;
  line-height: 0.9rem;
}
.customercardtopline {
  display: flex;
  flex-direction: row;
  border-bottom: dashed black 1px;
  justify-content: space-between;
}
.adminsearchbar {
  padding: 0.3rem 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  border-radius: 1rem;
  width: 30%;
}
.prodgroupcards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2rem;
  /* height: 70%; */
  flex-basis: 65%;
  border-right: gray dashed 1px;
}
.prodgroupcard {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
}
.prodgroupcard:hover {
  cursor: pointer;
}
.productlink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.productlink:hover:active {
  font-weight: bold;
}
.selectedprodgroup {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding-left: 2rem;
}
.selectedprods {
  display: flex;
  flex-direction: column;
}
.selectedproddetails {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  padding-left: 2rem;
}
.prodaddcard {
  flex-basis: 30%;
}
.prodnameadmin:hover {
  text-decoration: underline;
  cursor: pointer;
}
.prodnameadmin:active:hover {
  font-weight: bold;
}
/* .tradercard:after {
  width: 16%;
  content: "";
  border: 1px solid transparent;
} */
