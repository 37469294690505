* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.App {
  background-color: rgb(223, 223, 223);
  min-height: 100vh;
  height: auto;
  /* min-width: 1860px; */
}

.Login {
  width: 100%;
  background-color: rgb(223, 223, 223);
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.LoginForm {
  min-width: 25vw;
  margin: 0 auto;
  padding: 3.5rem 4rem;
  min-height: 55vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3rem;
  /* border: rgb(160, 182, 103) 1px solid; */
  box-shadow: 3px 3px 30px rgb(170, 170, 170);
}
.Logo {
  width: 225px;
  height: 123px;
  margin: 2.5rem 0;
}
.LoginForm h2 {
  font-family: "Roboto", sans-serif;
  /* padding-top: 3.5rem; */
  color: rgb(68, 65, 162);
  font-size: 2rem;
}
.LoginForm input {
  width: 80%;
  margin-bottom: 2rem;
  padding: 0.5rem;
  border: none;
  border-bottom: rgb(160, 182, 103) 1px solid;
}
.LoginForm button {
  width: 60%;
  padding: 0.8rem;
  background-color: white;
  border: rgb(68, 65, 162) 1px solid;
  border-radius: 1.3rem;
  margin: 1rem 0;
  margin-bottom: 1rem;
}
.LoginForm button:hover,
.loginForm button:focus {
  background-color: rgb(68, 65, 162);
  color: white;
  cursor: pointer;
}
.errormessage {
  color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
}
#chpwdmodal {
  width: 40%;
  height: auto;
}
.modal-main {
  position: fixed;
  background: white;
  width: 60%;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 3rem;
  box-shadow: 3px 3px 30px rgb(0, 0, 0);
  border-radius: 2rem;
  font-family: "Roboto", sans-serif;
}
.modal-main h1 {
  margin-bottom: 2rem;
}
.modal-main h2 {
  padding-bottom: 1rem;
  color: rgb(68, 65, 162);
}
.modal-main p {
  padding-bottom: 2rem;
}
.modal-main button {
  width: 60%;
  padding: 0.8rem;
  background-color: white;
  border-radius: 1.3rem;
  margin: 1rem 0;
  margin-bottom: 0;
  margin-top: 1rem;
}
.confirmbutton {
  border: rgb(68, 65, 162) 1px solid;
}
.confirmbutton:hover,
.confirmbutton:focus {
  background-color: rgb(68, 65, 162);
  color: white;
  cursor: pointer;
}
.cancelbutton {
  border: rgb(160, 182, 103) 1px solid;
  margin-bottom: 1rem;
}
.cancelbutton:hover,
.cancelbutton:focus {
  background-color: rgb(160, 182, 103);
  color: white;
  cursor: pointer;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.form-group input {
  min-width: 60%;
  margin-left: 1rem;
  border: none;
  border-bottom: rgb(160, 182, 103) 1px solid;
}
.form-group {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding-bottom: 1rem;
  width: 60%;
}

.form-group h2 {
  margin-bottom: 4rem;
}
.form-group label {
  flex: none;
  display: block;
  width: 140px;
}
.changePwdModalForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cpwdinputerror {
  color: red;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}
.cpwdinputerror p {
  padding: 0;
}

.react-grid-item {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 3px 3px 10px rgb(170, 170, 170);
}
.gridcontainer {
  padding-top: 9vh;
}
.layout {
  background-color: rgb(223, 223, 223);
  min-height: 100vh;
}

body {
  color: #333333;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  line-height: 18px;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
dl,
dt,
dd,
ol,
ul,
li,
table,
tr,
td,
form,
object,
embed,
article,
aside,
canvas,
command,
details,
fieldset,
figcaption,
figure,
footer,
group,
header,
hgroup,
legend,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
audio,
video {
  border: 0 none;
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none outside none;
}

a {
  text-decoration: none;
  color: #0088cc;
  cursor: pointer;
}

.fl {
  float: left;
  width: 49%;
}

.fr {
  float: right;
  width: 49%;
  text-align: right;
}

.container {
  /*margin: 0 auto;*/
  /*width: 1080px;*/
  margin: 20px;
}

#table-container {
  margin-top: 10px;
}

/* Table Sorter */
.tablesorter {
  background-color: #fff;
  margin: 10px 0 15px;
  text-align: left;
  border-spacing: 0;
  border: #ddd 1px solid;
  border-width: 1px 0 0 1px;
  font: 0.75rem Arial, Sans-serif; /*12px/18px*/
  border-collapse: separate;
}

.tablesorter th,
.tablesorter td {
  border: #ddd 1px solid;
  border-width: 0 1px 1px 0;
  overflow: hidden;
  white-space: nowrap;
}

/* header */
.tablesorter th,
.tablesorter thead td {
  font: 12px/18px Arial, Sans-serif;
  color: #555;
  background-color: #f5f5f5;
  padding: 1px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
}

.tablesorter tbody td,
.tablesorter tfoot th,
.tablesorter tfoot td {
  padding: 0px 4px;
  vertical-align: top;
}

.tablesorter .header,
.tablesorter .header-extra,
.tablesorter .tablesorter-header {
  padding: 4px 4px;
  white-space: normal;
  cursor: pointer;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid transparent;
  background: #f5f5f5 url(data:image/gif;base64,R0lGODlhBwAJAJEAAAAAAP///7u7u////yH5BAEAAAMALAAAAAAHAAkAAAINnGUpq8sOk4Ru0IZmKgA7) no-repeat right 5px center;
}

.tablesorter .header-extra {
  padding: 2px 4px;
  border-bottom: 1px solid #ddd;
  background-image: none;
}

.tablesorter th.headerSortAsc,
.tablesorter th.tablesorter-headerSortAsc {
  color: #333;
  background: #f5f5f5 url(data:image/gif;base64,R0lGODlhBwAJAKIAAAAAAP///wADM7u7u////wAAAAAAAAAAACH5BAEAAAQALAAAAAAHAAkAAAMRSCra8u5BSZsdOA+it8oW1yQAOw==) no-repeat right 5px center;
}

.tablesorter th.headerSortDesc,
.tablesorter th.tablesorter-headerSortDesc {
  color: #333;
  background: #f5f5f5 url(data:image/gif;base64,R0lGODlhBwAJAKIAAAAAAP///wADM7u7u////wAAAAAAAAAAACH5BAEAAAQALAAAAAAHAAkAAAMRSDra8+5BSZsVOAuit8oW1yQAOw==) no-repeat right 5px center;
}

.tablesorter td {
  color: #333;
}

.tablesorter tbody > tr:hover > td {
  color: #000;
  background: #ddeaf9;
  /* font-weight: bold; */
}
/* End Table Sorter */

#tools {
  margin: 5px 0;
  padding: 5px 5px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: #000;
  font-size: 13px;
}

select {
  margin-right: 10px;
}

code {
  background-color: #eeeeee;
}

h2 {
  margin-bottom: 10px;
}
.tablesorter thead td .sales-filter {
  margin-right: 10px;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.modal-main {
  position: fixed;
  background: white;
  width: 30%;
  height: 70vh;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 3rem;
  box-shadow: 3px 3px 30px rgb(0, 0, 0);
  border-radius: 3rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.positionModalForm .form-group input {
  flex: 4 1;
  flex-basis: 70%;
  /* width: 50%; */
  margin-left: 1rem;
}
.positionModalForm textarea {
  /* margin-left: 1rem; */
  margin-top: 0.5rem;
  width: 100%;
  height: 15vh;
}
.positionModalForm .form-group {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding-bottom: 1rem;
}
.positionModalForm h2 {
  margin-bottom: 0.5rem;
}
.positionModalForm .form-group label {
  flex: 1 1;
  /* flex: none; */
  /* display: block; */
  /* width: 130px; */
  flex-basis: 20%;
}
.positionModalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.positionModalForm .form-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.positionModalForm ul {
  max-height: 30vh;
  overflow-y: auto;
}
.positionModalForm .presearchresults {
  flex: 1 1;
  flex-basis: 15%;
}
.positionModalForm .QSsearchresults-container {
  flex: 4 1;
  flex-basis: 40%;
}

/* .searchfield {
  display: flex;
  flex-direction: column;
} */
.searchresults {
  position: relative;
  top: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  /* border: solid 1px gray; */

  /* display: table; */
}

.flexbreak {
  flex-basis: 100%;
  height: 0;
}
.presearchresults {
  /* min-width: 100%; */
  height: 2px;
  flex: 1 1;
  flex-basis: 22%;
}
.searchresults {
  /* display: block; */
  flex: 4 1;
  flex-basis: 70%;
}
.searchresults li:hover {
  background-color: #ddeaf9;
  cursor: pointer;
  /* color: white; */
}

/* .sales-filter-QSDate input {
  width: 70px;
  width: 100%;
} 
.sales-filter-QSID input {
  width: 40px;
  width: 100%;
} 
.sales-filter-saleType input {
  width: 90px;
}
.sales-filter-WGP input {
  width: 70px;
}
.sales-filter-WGS input {
  width: 70px;
}
.sales-filter-abbreviation input {
  width: 90px;
  width: 100%;
}
.sales-filter-supplier input {
  width: 90px;
}
.sales-filter-customer input {
  width: 90px;
}
.sales-filter-beginning input {
  width: 90px;
}
.sales-filter-finish input {
  width: 90px;
}
.sales-filter-portOfLoad input {
  width: 90px;
}
.sales-filter-porOfDestination input {
  width: 90px;
} */
.crudbuttons {
  display: flex;
}
.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.react-confirm-alert-body {
  text-align: center;
  box-shadow: 3px 3px 30px rgb(0, 0, 0);
  height: 200px;
}
.react-confirm-alert-body > h1 {
  padding-bottom: 1.3rem;
  color: rgb(68, 65, 162);
}
.react-confirm-alert-button-group {
  justify-content: center;
}
.react-confirm-alert-button-group > button {
  background-color: transparent;
  color: rgb(68, 65, 162);
  padding: 0.3rem 1.5rem;
  border: none;
  border: 2px solid rgb(68, 65, 162);
  /* background-color: rgb(68, 65, 162); */
  align-self: center;
  border-radius: 3rem;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.react-confirm-alert-button-group > button:hover {
  cursor: pointer;
  background-color: rgba(68, 65, 162, 0.75);
  color: white;
}
.sales-filter input {
  width: 100%;
}
td[id^="materialCost"] {
  text-align: right;
}
td[id^="oFreight"] {
  text-align: right;
}
td[id^="priceBeforeInterest"] {
  text-align: right;
}
td[id^="tradingProfit"] {
  text-align: right;
}
td[id^="tradingMargin"] {
  text-align: right;
}

h3 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  display: inline-block;
  background-color: white;
  width: 100%;
}
.saleslist {
  overflow: auto;
  height: 100%;
}
.saleslisttitle {
  font-family: "Roboto", sans-serif;
  color: rgb(68, 65, 162);
  /* padding-bottom: 1rem; */
}
.refreshicon {
  height: 25px;
  cursor: pointer;
}
.salestitleline {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
}
.clearfilterbutton {
  border-radius: 1.5rem;
  border: 1px solid rgb(68, 65, 162);
  margin-right: 1rem;
  width: 200px;
  color: rgb(68, 65, 162);
  background-color: white;
}
.clearfilterbutton:hover {
  cursor: pointer;
  color: white;
  background-color: rgb(68, 65, 162);
}

.salesQS {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* border: solid black 1px; */
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.salesQS-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.salesQS-form section {
  padding: 1rem;
}
.salesQS-form fieldset {
  border: solid 1px black;
  border-style: groove;
  padding: 0.5rem;
  margin-bottom: 1rem;
  /* background-color: rgba(160, 182, 103, 0.2); */
}
.salesQS-form legend {
  /* background-color: white; */
  font-weight: 900;
  color: rgb(68, 65, 162);
  font-size: 1rem;
}
.salesQS-form input {
  border: none;
  border-bottom: black solid 1px;
  line-height: 1.3rem;
  padding-left: 5px;
}
.salesQS-form .form-group {
  width: 100%;
  padding-bottom: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.salesQS-form .form-group label {
  /* flex: 1; */
  min-width: 120px;
  flex-basis: 32%;
}
.salesQS-form .form-group input {
  /* flex: 1; */
  flex-basis: 42%;
  min-width: 42%;
}
#salesQS-1 .form-group input {
  flex-basis: 60%;
  min-width: 60%;
}
#salesQS-1 .form-group label {
  min-width: 70px;
  flex-basis: 25%;
}
#salesQS-1 {
  /* border: solid 1px black; */
  /* flex: 1; */
  flex-basis: 25%;
  min-height: 100%;
}
#salesQS-2 {
  /* border: solid 1px black; */
  /* flex: 1; */
  /* flex-basis: 22%;
   */
  min-height: 100%;
  flex-basis: 25%;
}
#salesQS-3 {
  /* border: solid 1px black; */
  /* flex: 1; */
  /* display: flex; */
  /* flex-basis: 50%; */
  flex-basis: 50%;
  min-height: 100%;
}
#salesQS-3-fieldset {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 86%;
}
#salesQS-3-col1 {
  /* border: solid 1px black; */
  flex: 1 1;
  flex-basis: 50%;
}
#salesQS-3-col2 {
  /* border: solid 1px black; */
  flex: 1 1;
  flex-basis: 50%;
}
.salesQS-form button {
  width: 32%;
  margin-right: 0.5rem;
  padding: 0.3rem 1.5rem;
  border: 2px solid rgb(68, 65, 162);
  background-color: transparent;
  border-radius: 3rem;
  margin-top: 0.5rem;
}
.salesQS-form button:hover {
  background-color: rgb(68, 65, 162);
  color: white;
}
.QSfig {
  text-align: right;
  padding-right: 5px;
}
.QSfig2 {
  text-align: right;
  padding-right: 15px;
}
#shipmenttyperadio input {
  min-width: 0;
  margin-left: 0;
  flex-basis: 5%;
}
#shipmenttyperadio label {
  flex-basis: 15%;
}
.WGPSelect,
.USWGPSelect {
  margin-left: 1rem;
  flex: 1 1;
  flex-basis: 20%;

  -webkit-appearance: none;

          appearance: none;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  background-color: white;
}
.WGPSelect option,
.USWGPSelect option {
  overflow: hidden;
  white-space: nowrap;
  background-color: white;
}
.saletype-group {
  display: flex;
  flex-direction: row;
}
.saletype-group label {
  flex: 1 1;
}
.salesQS-form .soldcheckbox {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
}
.salesQS-form .soldcheckbox label {
  margin-left: 0.5rem;
}
#QSbuttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.salesQStitleline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.salesQSnavbuttons {
  display: flex;
  flex-direction: row;
}
.salesQSnavbuttons button {
  border-radius: 1rem;
  margin: 0 1rem;
  width: 60px;
  border: none;
}
.salesQSnavbuttons button:hover {
  cursor: pointer;
  background-color: rgb(68, 65, 162);
  color: white;
}
#exchangerate label {
  display: flex;
  flex: 1 1 20%;
}

#exchangerate input {
  display: flex;
  flex: 1 1 30%;
}
#exchangerate button {
  display: flex;
  flex: 1 1 40%;
  margin-top: 1rem;
  margin-right: 0;
  margin-left: 0;
  background-color: rgb(68, 65, 162);
  color: white;
  border: none;
}
#exchangerate button:hover {
  cursor: pointer;
  background-color: rgb(123, 120, 219);
  /* border: none; */
}
.QSindexbox {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.QSIDerror {
  color: red;
  margin-left: 90px;
}
.editmode {
  background-color: rgb(246, 246, 246) !important;
}
.unlockicon {
  height: 20px;
  margin-left: 5px;
}
.lockicon {
  height: 20px;
  margin-left: 5px;
}
.custom-ui {
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  min-width: 550px;
  min-height: 200px;
  text-align: center;
}
.custom-ui h1 {
  color: rgb(68, 65, 162);
  margin-bottom: 2rem;
}
.confirmmsg {
  max-width: 450px;
  margin-bottom: 2rem;
}
.custom-ui button {
  padding: 0.3rem 1.5rem;
  border: solid 2px rgb(68, 65, 162);
  background-color: white;
  border-radius: 3rem;
  margin: 0 1rem;
}
.custom-ui button:hover {
  cursor: pointer;
  background-color: rgba(68, 65, 162, 0.75);
  color: white;
}
.custom-ui ul {
  margin-bottom: 1.5rem;
}
.editsline {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.editsheader {
  font-weight: bold;
}
.edititem {
  min-width: 130px;
  text-align: left;
}
.editfig {
  text-align: right;
  min-width: 130px;
}
.saleboxes {
  display: flex;
  flex-direction: row;
}
.bottompopupcalculator {
  position: absolute;
  /* top: 10px; */
  width: 380px;
  background-color: rgb(230, 230, 230);
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  z-index: 1;
}
.bottompopupcalculator .form-group label {
  min-width: 150px;
}
.bottompopupcalculator::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid rgb(230, 230, 230);
  border-right: 10px solid transparent;
  border-bottom: 15px solid rgb(230, 230, 230);
  border-top: 10px solid transparent;
  left: 120px;
  top: -12px;
}
.bottompopupcalculatorlg {
  position: absolute;
  display: flex;
  /* top: 10px; */
  width: 680px;
  background-color: rgb(230, 230, 230);
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  z-index: 1;
}
.bottompopupcalculatorlg-1 {
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
}
.bottompopupcalculatorlg-1 {
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
}
.bottompopupcalculatorlg .form-group label {
  min-width: 150px;
}
.bottompopupcalculator ul {
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.saveQSbutton:hover {
  cursor: pointer;
}
#QSbuttons span {
  padding-right: 2rem;
  color: green;
}
.storagefields .form-group label {
  min-width: 142px;
}
.storagefields .form-group input {
  flex-basis: 36%;
  min-width: 36%;
  margin-left: 4px;
}
.storagefields .form-group select {
  flex-basis: 48% !important;
  min-width: 33%;
}
.form-group {
  justify-content: space-between;
}
.salesQS-form button:focus {
  background-color: rgb(68, 65, 162);
  color: white;
}

/* .searchfield {
  display: flex;
  flex-direction: column;
} */
.QSsearchresults-container {
  position: relative;
  display: flex;
  /* border: solid 1px gray; */
}
.QSsearchresults {
  background-color: white;
  position: absolute;
  top: -20px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  width: 330px;
  border: solid 1px gray;
  max-height: 20rem;
  overflow-y: auto;
  z-index: 2;

  /* display: table; */
}
.QSsearchresults-border {
}
.QSsearchresults li {
  padding-left: 10px;
  /* border-bottom: solid 1px rgb(160, 182, 103); */
}
.QSsearchresults-hide {
  display: none;
}
.flexbreak {
  flex-basis: 100%;
  height: 0;
}
.presearchresults {
  /* min-width: 100%; */
  /* height: 2px; */
  flex: 1 1;
  flex-basis: 22%;
}
.QSsearchresults {
  /* display: block; */
  flex: 4 1;
  flex-basis: 70%;
}
.QSsearchresults li:hover {
  background-color: #ddeaf9;
  cursor: pointer;
  /* color: white; */
}

.QSmodal {
  height: 110%;
}
.QSmodal .modal-main {
  width: 90%;
  height: 80%;
}
.QSmodal h2 {
  padding-top: 0.5rem;
}
.QSModalForm {
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  width: 100%;
}
.QSModalForm fieldset {
  border: solid 1px black;
  border-style: groove;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.QSModalForm section {
  padding: 1rem;
}
.QSModalForm .form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding-bottom: 1rem;
  flex-wrap: wrap;
}

.QSModalForm input {
  flex: 1 1;
  flex-basis: 40%;
  margin-right: 1rem;
  min-width: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.QSModalForm label {
  flex: 1 1;
  flex-basis: 15%;
  width: 100%;
  /* width: 0; */
}
.QSModalForm legend {
  /* background-color: white; */
  font-weight: 900;
  color: rgb(68, 65, 162);
  font-size: 1rem;
}
.QSModalForm p {
  padding: 0;
}
.QSModalForm .shipmentType label {
  padding-right: 1rem;
}
.QSModalForm .shipmentType {
  margin-bottom: 0.6rem;
}
.QSModalForm .searchresults {
  position: absolute;
  flex: 4 1;
  top: -20px;
  left: -15px;
  width: 300px;
  background-color: white;
  border: solid 1px black;
  max-height: 30vh;
  overflow-y: auto;
  padding-left: 0.3rem;
  padding-right: 0;
}
.QSModalForm .searchresults li {
  width: 100%;
}
.QSModalForm .presearchresults {
  flex: 1 1;
  flex-basis: 20%;
}
.QSModalForm .QSsearchresults-container {
  position: relative;
  display: flex;
}
#editQS-1 {
  flex: 1 1;
  flex-basis: 12%;
  min-height: 100%;
}
#editQS-2 {
  flex: 1 1;
  flex-basis: 12%;
  min-height: 100%;
}
#editQS-3 {
  flex-basis: 60%;
  flex: 4 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
#editQS-3 fieldset {
  display: flex;
  width: 100%;
}
.QSedit-buttons {
  display: flex;
  flex-direction: row;
}
.QSedit-buttons button {
  margin: 0 0.2rem;
}
#editQS-3-col1 {
  flex: 1 1;
  flex-basis: 50%;
}
#editQS-3-col1 fieldset {
  display: flex;
  flex-direction: column;
}
#editQS-3-col2 {
  flex: 1 1;
  flex-basis: 50%;
}
#editQS-3-col2 fieldset {
  display: flex;
  flex-direction: column;
}
.WGPSelect2 {
  overflow: hidden;
  white-space: nowrap;
  width: 140px;
  margin-left: 1rem;
}
.WGPSelect2 option {
  overflow: hidden;
  white-space: nowrap;
}
.QSeditmodaltitle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  /* margin-top: 1rem; */
}
.QSeditmodaltitle button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  margin: 0.5rem 0.9rem 0 0.9rem;
}
.QSeditmodalsearch {
  /* margin: 0 0rem; */
}

td[id^="quantity"] {
  text-align: right;
}
td[id^="FOB"] {
  text-align: right;
}
.editbutton {
  cursor: pointer;
  background-color: transparent;
  color: rgb(160, 182, 103);
  padding: 0.1rem 0.5rem;
  border: 1px solid rgb(68, 65, 162);
  align-self: center;
  border-radius: 1rem;
  margin-left: 0.5rem;
  margin-top: 2px;
  /* font-family: "Roboto", sans-serif; */
  /* font-size: 1rem; */
}
.editbutton:hover {
  color: white;
  background-color: rgb(68, 65, 162);
}

table thead tr:nth-child(1) th {
  /* background: RED; */
  position: -webkit-sticky;
  position: sticky;
  top: 18px;
  z-index: 10;
  /* background-color: blue; */
}
.tablesorter thead td {
  position: -webkit-sticky;
  position: sticky;
  top: 41px;
  z-index: 11;
  /* background-color: blue; */
}

.tablesorter .header {
  border-bottom: none;
  background-color: rgb(160, 182, 103);
  color: white;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
}
.tablesorter .lastheader {
  background: #f5f5f5;
}
.filter-WGP input {
  width: 70px;
}
.filter-abbreviation input {
  width: 110px;
}
.filter-packaging input {
  width: 70px;
}
.filter-Start input {
  width: 90px;
}
.filter-End input {
  width: 90px;
}
.filter-FOB input {
  width: 90px;
}
.filter-quantity input {
  width: 90px;
}
.filter-year input {
  width: 90px;
}

.uspositionreport {
  overflow: auto;
  height: 100%;
}
#uspositionreporttable {
  margin-top: 0.5rem;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
}
#uspositionreporttable td {
  padding-right: 3.5rem;
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
  font-family: "Roboto", sans-serif;
}
#uspositionreporttable th {
  padding-right: 3rem;
  font-size: 0.9rem;
  border: none;
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
  top: 0px;
}
#uspositionreporttable tr:hover {
  background-color: #ddeaf9;
  cursor: pointer;
}
#uspositionreporttable .totals {
  border-top: 1px solid black;
  height: 2rem;
  vertical-align: top;
}
.usprodgroup {
  background-color: rgb(68, 65, 162);
  color: white;
}
.mktpriceupdate {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mktpriceupdatemenu {
  position: absolute;
  /* right: 100%; */
  top: 20px;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid rgb(68, 65, 162);
  z-index: 10;
}
.mktpriceupdatemenu button {
  border-radius: 1rem;
  padding: 0.25rem 2rem;
  background-color: rgb(160, 182, 103);
  color: white;
  margin-top: 0.5rem;
  border: none;
}
.mktpriceupdatemenu button:hover {
  cursor: pointer;
  background-color: rgb(179, 197, 133);
}

#navigation {
  width: 100%;
  position: fixed;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: solid rgb(68, 65, 162) 1px;
  align-items: center;
  box-shadow: 0 8px 8px 0 rgba(160, 182, 103, 0.2);
  height: 6vh;
  padding: 0.3rem 3rem;
  font-family: "Roboto", sans-serif;
  /* background-color: white; */
  background-color: rgb(160, 182, 103);
  color: white;
}
#navigation .logoutButton {
  background-color: transparent;
  color: white;
  padding: 0.3rem 1.5rem;
  border: none;
  /* border: 2x solid rgb(68, 65, 162); */
  background-color: rgb(68, 65, 162);
  align-self: center;
  border-radius: 3rem;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
#navigation .logoutButton:hover {
  cursor: pointer;
  background-color: rgba(68, 65, 162, 0.75);
  color: white;
}
h2 {
  margin: 0;
}
.welcomemsg {
  display: flex;
  align-items: baseline;
  color: white;
}
.welcomemsg .cls-1 {
  fill: white;
  stroke: white;
  stroke-miterlimit: 0;
  stroke-width: 0;
}

.Logoheader {
  width: 2rem;
  height: 1.3rem;
  margin: 0 0.5rem;
  color: white;
}
.userwelcome {
  display: flex;
  font-size: 1rem;
}

.positionreport {
  overflow: auto;
  height: 100%;
}
.positionreporttitle {
  font-family: "Roboto", sans-serif;
  color: rgb(68, 65, 162);
  background-color: white;
  z-index: 90;
}
#positionreporttable {
  margin-top: 0.5rem;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
}

#positionreporttable td {
  padding-right: 3.5rem;
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
  font-family: "Roboto", sans-serif;

  /* background-color: white; */
}
#positionreporttable th {
  padding-right: 3rem;
  font-size: 0.9rem;
  border: none;
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
  top: 0px;
}

.fig {
  text-align: right;
}
#positionreporttable tr:hover {
  background-color: #ddeaf9;
  cursor: pointer;
}
#positionreporttable .totals {
  border-top: 1px solid black;
  height: 2rem;
  vertical-align: top;
}
.prodgroup {
  background-color: rgb(160, 182, 103);
  color: white;
}
.product {
  /* background-color: rgb(228, 236, 204); */
}
.positionreporttitleline {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/* .postooltip {
  border: solid 1px rgb(68, 65, 162) !important;
  font-family: "Roboto", sans-serif !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
  opacity: 1 !important;
} */

.postooltipsource {
  position: relative;
}
.postooltip {
  position: absolute;
  z-index: 50;
  background-color: white;
  border: rgb(68, 65, 162) 1px solid;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 900px;
  top: 19px;
  left: 80%;
}
.posmatchitem {
  display: flex;
  flex-direction: row;
}
.posmatchitem p,
.posmatchitem h4 {
  flex-basis: 20%;
}
.react-tabs__tab-panel--selected {
  overflow: auto;
  height: 90%;
}
.react-tabs {
  height: 100%;
}

h3 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  display: inline-block;
  background-color: white;
  width: 100%;
}
.positionslist {
  overflow: auto;
  height: 100%;
}
.positionslisttitle {
  font-family: "Roboto", sans-serif;
  color: rgb(68, 65, 162);
}
.positionstitleline {
  display: flex;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.positionadd {
  overflow: auto;
  height: 100%;
}
.positionaddtitle {
  font-family: "Roboto", sans-serif;
  color: rgb(68, 65, 162);
}
#positionaddform {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
#positionaddform .form-group {
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
#positionaddform label {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  flex: 1 1;
  flex-basis: 20%;
  margin-top: 0.7rem;
}
#positionaddform input {
  margin-top: 0.6rem;
  padding: 0.3rem;
  /* border: none; */
  border: 1px solid rgb(160, 182, 103);
  margin-left: 0;
  flex: 4 1;
  flex-basis: 80%;
  font-family: "Roboto", sans-serif;
}
#positionaddform textarea {
  margin-top: 0.6rem;
  margin-left: 0;
  flex: 4 1;
  flex-basis: 80%;
  height: 20vh;
  resize: none;
  overflow-y: auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.95rem;
}
#positionaddform button {
  margin-top: 1.5rem;
  padding: 0.3rem 1.5rem;
  border: 2px solid rgb(68, 65, 162);
  background-color: transparent;
  border-radius: 3rem;
}

#positionaddform button:hover {
  background-color: rgb(68, 65, 162);
  color: white;
  cursor: pointer;
}
.posadderror {
  color: red;
  font-size: 0.85rem;
  margin-left: 20%;
  /* margin-bottom: 0.5rem; */
}
#positionaddform .presearchresults {
  /* width: 100px; */
  flex: 1 1;
  flex-basis: 10%;
}
#positionaddform .QSsearchresults-container {
  flex: 4 1;
  flex-basis: 40%;
}

.AddProdModal {
  height: 110%;
}
.AddProdModal .modal-main {
  width: 80%;
  height: 70%;
}
.AddProdModal .addproduct-form {
  display: flex;
  flex-direction: row;
  height: 90%;
}
.addprod-1 {
  flex: 1 1;
  border-right: 1px dashed gray;
  min-height: 100%;
}
.addprod-1 p {
  padding-bottom: 0;
}
.addprod-2 {
  flex: 1 1;
  border-right: 1px dashed gray;
}
.addprod-3 {
  flex: 1 1;
  /* border: 1px solid black; */
}
.addnewprodline {
  display: flex;
}
.AddProdModal h3 {
  color: rgb(160, 182, 103);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.AddProdModal .searchresults li:hover {
  background-color: #ccccca;
}
.addproduct-form .form-group {
  width: 100%;
  flex-wrap: wrap;
}
.addproduct-form label {
  flex: 1 1;
  flex-basis: 20%;
}
.addproduct-form input {
  flex: 1 1;
  flex-basis: 45%;
  margin-right: 1rem;
}
.addproduct-form .presearchresults {
  flex: 1 1;
  flex-basis: 20%;
}
.addproduct-form .QSsearchresults-container {
  flex: 1 1;
  flex-basis: 45%;
  max-height: 20vh;
  overflow-y: auto;
  background-color: #e9e9e7;
  margin-right: 1rem;
  padding-right: 0;
}
.addproduct-form ul {
  padding-right: 0;
}

.Register {
  width: 100%;
  background-color: rgb(223, 223, 223);
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.RegisterForm {
  min-width: 30vw;
  margin: 0 auto;
  padding: 3.5rem 4rem;
  min-height: 55vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3em;
  /* border: rgb(160, 182, 103) 1px solid; */
  box-shadow: 3px 3px 30px rgb(170, 170, 170);
}
.Logo {
  width: 225px;
  height: 123px;
  margin: 2.5rem 0;
}
.RegisterForm h2 {
  font-family: "Roboto", sans-serif;
  /* padding-top: 3.5rem; */
  color: rgb(68, 65, 162);
  font-size: 2rem;
}
.RegisterForm input {
  width: 80%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-bottom: rgb(160, 182, 103) 1px solid;
}
.RegisterForm button {
  width: 60%;
  padding: 0.8rem;
  background-color: white;
  border: rgb(68, 65, 162) 1px solid;
  border-radius: 1.3rem;
  margin: 1rem 0;
  margin-bottom: 1rem;
}
.RegisterForm button:hover {
  background-color: rgb(68, 65, 162);
  color: white;
  cursor: pointer;
}
.errormessage {
  color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}
.successmessage {
  color: rgb(160, 182, 103);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}

.inputerror {
  color: red;
  margin-top: 0;
  padding-top: 0;
  font-size: 0.7rem;
  overflow-wrap: break-word;
  max-width: 17vw;
}

#navbarcontainer {
  width: 100%;
  /* background-color: white; */
  background-color: rgb(68, 65, 162);
  position: fixed;
  display: flex;
  z-index: 21;
  top: 6vh;
  border-bottom: solid rgb(68, 65, 162) 1px;

  /* padding: 0rem 0rem; */
}
#navbar {
  width: 60%;
  justify-content: space-between;
  display: flex;
}
#navbar li {
  list-style-type: none;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  flex: 1 1;
  text-align: center;
}
#navbar li:hover {
}
#navbar a {
  width: 100%;
  text-decoration: none;
  display: block;
  padding: 0.3rem 0;
  color: white;
}
#navbar a:hover {
  cursor: pointer;
  background: rgb(123, 120, 219);
  color: white;
}
.navbaractive {
  background-color: rgb(223, 223, 223);
  background-color: rgb(160, 182, 103);
  background-color: rgb(123, 120, 219);
  color: white;
  /* border-bottom: dashed 1px black; */
}

.keyfigures-list {
  display: flex;
  width: 100%;
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* margin-top: 0.5rem; */
  /* padding: 1rem; */
  text-align: center;
  font-family: "Roboto", sans-serif;
  overflow-y: auto;
}
.keyfigures-list h4 {
  color: rgb(68, 65, 162);
}
.keyfigure-volume {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  padding: 2rem 0 1rem 0;
  /* border-bottom: dashed 1px rgb(160, 182, 103); */
}
.keyfigure-revenue {
  display: flex;
  flex-basis: 20%;
  flex-direction: column;

  padding: 2rem 0 1rem 0;
  /* border-bottom: dashed 1px rgb(160, 182, 103); */
}
.keyfigure-profit {
  display: flex;
  flex-basis: 20%;
  flex-direction: column;

  padding: 2rem 0 1rem 0;
  /* border-bottom: dashed 1px rgb(160, 182, 103); */
}
.keyfigure-profitpmt {
  display: flex;
  flex-basis: 20%;
  flex-direction: column;

  padding: 2rem 0 1rem 0;
  /* border-bottom: dashed 1px rgb(160, 182, 103); */
}
.keyfigure-profitpct {
  display: flex;
  flex-basis: 20%;
  flex-direction: column;

  padding: 2rem 0 1rem 0;
}
.kfig {
  /* display: flex;
  flex-basis: 100%;
  flex-wrap: wrap; */
  margin-bottom: 1rem;
  color: rgb(160, 182, 103);
}
.keyfigures-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 3rem;
}
.keyfigures-buttons button {
  margin: 0 1rem;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  border: rgb(68, 65, 162) 1px solid;
  background-color: white;
}

.mgmtchart h3 {
  position: fixed;
  left: 0;
  padding-left: 1rem;
  padding-top: 0.7rem;
  z-index: -10;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  /* padding-bottom: 1rem; */
}
.mgmtcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.mgmtgridcontainer {
  /* margin-left: 10%; */
  display: flex;
  flex-basis: 90%;
  display: inline-block;
  width: 100%;
  position: relative;
  left: 230px;
}
.mgmtnav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 19%;
  width: 230px;
  background-color: rgb(65, 64, 73);
  /* background-color: black; */
  color: white;
  padding: 8rem 2rem;
  height: 100vh;
}
.mgmtnav a {
  font-size: 0.9rem;
  color: white;
}
/* #profitability {
  width: 900px !important;
} */

.sunburst {
  padding-top: 6rem;
  display: flex;
  width: 1000px;
}
#Sunburst {
  display: flex;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
#Sunburst-svg {
  /* transform: scale(0.7); */
  display: flex;
  /* transform: translateX(-50%); */
}

#profitability {
  overflow: hidden;
  padding-bottom: 5rem;
}
#profitability h3 {
  padding-bottom: 0.5rem;
}
.periodfilter {
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  align-items: baseline;
}
.periodfilterbuttons {
  display: flex;
  flex-direction: row;
  /* flex-basis: 50%; */
  width: 600px;
  justify-content: space-between;
}
.customdateinputs {
  display: flex;
  flex-direction: row;
  flex-basis: 40%;
  align-items: stretch;
  font-size: 0.8rem;
  margin: 0 0 0 1rem;
}
.customdateinputs label {
  margin: 0 0.3rem 0 1rem;
}
.customdateinputs button {
  border-radius: 1rem;
  border: none;
  padding: 0.3rem 1rem;
  margin-left: 1rem;
  color: white;
  background-color: rgb(68, 65, 162);
}
.customdateinputs button:hover {
  cursor: pointer;
  background-color: rgb(101, 98, 184);
}
.periodfilterbutton {
  border-radius: 1rem;
  border: none;
  padding: 0.3rem 1rem;
}
.periodfilterbutton:hover {
  cursor: pointer;
  background-color: rgb(169, 167, 218);
  color: white;
}
.active,
.active:hover {
  background-color: rgb(68, 65, 162);
  color: white;
}
.prsummarystats {
  display: flex;
  flex-direction: row;
  padding: 1.3rem;
  justify-content: space-between;
}
.prstatgroup {
  display: flex;
  flex-direction: column;
  flex-basis: 15%;
}
.prstatgroup h4 {
  font-size: 1rem;
}
.prstatgroup p {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.prgroupby {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  width: 770px;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.profitabilityreport {
  height: 75%;
  overflow: auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.profitabilityreport p {
  font-size: 0.8rem;
}

.profitabilityreportline {
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: -30;
  position: relative;
}
.profitabilityreportcolumn {
  display: flex;
  flex-basis: 20%;
}
.prfig {
  text-align: right;
  width: 100px;
  display: inline-block;
  padding-right: 3rem;
}
.prtotal {
  font-weight: bold;
  margin-top: 1rem;
}
.prheader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-weight: bold;
  border-bottom: 1px solid rgb(68, 65, 162);
  margin-bottom: 0.5rem;
  background-color: white;
  z-index: 2;
}
.prmonth {
  margin-bottom: 0.5rem;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  /* margin-top: 0.5rem; */
  top: 19px;
  font-size: 0.9rem;
  width: 100%;
  border-bottom: 1px solid rgb(68, 65, 162);
  z-index: -1;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}
.profitabilityreportline:nth-child(even) {
  background-color: rgb(245, 245, 245);
}
.profitabilityreportline:hover {
  background-color: #ddeaf9;
}
.prfiltermenu {
  position: absolute;
  background-color: white;
  z-index: 2000;
  min-height: 180px;
  min-width: 200px;
  max-height: 180px;
  top: 20px;
  border: 1px solid black;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  padding-right: 0;
}
.prcheckgroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.prcheckgroup label {
  margin-left: 0.5rem;
  font-weight: 400;
}
.prcheckgroup label:hover {
  cursor: pointer;
}
.ellipsis:hover {
  cursor: pointer;
}
.prcheckgroup:hover {
  background-color: rgb(222, 226, 231);
  cursor: pointer;
}
.prselect {
  border-bottom: dashed 1px rgb(190, 190, 190);
}
.prselect:hover {
  background-color: rgb(222, 226, 231);
  cursor: pointer;
}
.prdisplay-none {
  display: none !important;
}
.profreporttitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profreporttitle button {
  background-color: rgb(68, 65, 162);
  color: white;
  padding: 0.25rem 2rem;
  width: 150px;
  border-radius: 1rem;
  border: none;
}
.profreporttitle button:hover {
  cursor: pointer;
  background-color: rgb(96, 93, 184);
}

.adminpage {
  padding-top: 6.5rem;
  /* padding-left: 3rem; */
  display: flex;
  flex-direction: row;
  /* height: 100vh; */
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}

.adminnav {
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  /* background-color: yellow; */
  padding: 0;
  /* margin-top: 0; */
}
.adminnav button {
  width: 100%;
  margin: 0;
  padding: 1rem;
  border: gray dashed 1px;
}
.displayblock {
  display: block;
}
.displaynone {
  display: none;
}

.adminresults {
  display: flex;
  flex-basis: 55%;
  background-color: white;
  padding: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-flow: row wrap;
  justify-content: flex-start;
  height: 90vh;

  /* flex-flow: wrap; */
}
.adminedit {
  display: flex;
  flex-basis: 33%;
  padding: 2rem;
  padding-left: 4rem;
  flex-direction: column;
}
.editCustomer {
  margin-bottom: 1rem;
}

.editTrader fieldset,
.editCustomer fieldset,
.editProdGroup fieldset,
.editProdCatName fieldset,
.editProdName fieldset {
  border: 1px solid black;
  border-style: groove;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 1rem;
}
.editTrader fieldset legend,
.editCustomer fieldset legend,
.editProdGroup fieldset legend,
.editProdCatName fieldset legend,
.editProdName fieldset legend {
  font-weight: bold;
  font-size: 1rem;
}
.traderlistresults,
.customerlistresults {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 85vh;
  overflow-x: hidden;
  /* margin: 0; */
}
.tradercard,
.customercard {
  display: flex;
  flex-direction: column;
  /* flex-basis: 16%; */
  /* width: 300px; */
  flex-basis: 30%;
  min-width: 293px;
  background-color: #ededed;
  height: 150px;
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  overflow-x: hidden;
  /* margin: 1rem; */
}
.customercard:active:hover,
.tradercard:active:hover {
  background-color: rgb(207, 207, 207);
}
.tradercard h4 {
  border-bottom: dashed 1px black;
}
.customercard {
  font-size: 0.85rem;
  height: 170px;
}

&:nth-child(6n-1) {
  margin-left: 2%;
  margin-right: 2%;
}
.tradercard:hover,
.customercard:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: rgb(226, 226, 226);
}

.traderemail {
  font-size: 0.8rem;
  padding: 1rem 0;
}
.traderrole {
  font-size: 0.8rem;
}
.edittrader-form-group,
.editcustomer-form-group,
.editprodgroup-form-group,
.editprodcatname-form-group,
.editprodname-form-group {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}
.edittrader-form-group label {
  display: flex;
  flex-basis: 20%;
  padding: 0.5rem 0;
}
.editcustomer-form-group label,
.editprodgroup-form-group label,
.editprodcatname-form-group label,
.editprodname-form-group label {
  display: flex;
  flex-basis: 25%;
  padding: 0.5rem 0;
}
.edittrader-form-group input,
.edittrader-form-group select {
  display: flex;
  flex-basis: 75%;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
}
.editcustomer-form-group input,
.editcustomer-form-group textarea,
.editprodgroup-form-group input,
.editprodcatname-form-group input,
.editprodname-form-group input,
.prodnamesearch {
  display: flex;
  flex-basis: 70%;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.prodnamesearchv {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
}
.editcustomer-form-group textarea {
  height: 100px;
}
.edittrader-form-group .activeradio {
  flex-basis: 20%;
  margin: 0.5rem 0;
}
.editTrader button,
.editCustomer button,
.editProdGroup button,
.editProdCatName button,
.editProdName button {
  margin-top: 1.5rem;
  width: 95%;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: rgb(68, 65, 162);
  border: none;
  color: white;
}
.editTrader button:hover,
.editCustomer button:hover,
.editProdGroup button:hover,
.editProdCatName button:hover,
.editProdName button:hover {
  background-color: rgb(123, 120, 219);
  cursor: pointer;
}

.customercountry {
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
}
.customercard ul {
  margin-top: 0.5rem;
}
.customercard ul li {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
}
.addresslabel {
  display: flex;
  flex-basis: 24%;
  font-size: 0.8rem;
  line-height: 0.9rem;
}
.addressfield {
  display: flex;
  flex-basis: 76%;
  font-size: 0.6rem;
  line-height: 0.9rem;
}
.customercardtopline {
  display: flex;
  flex-direction: row;
  border-bottom: dashed black 1px;
  justify-content: space-between;
}
.adminsearchbar {
  padding: 0.3rem 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  border-radius: 1rem;
  width: 30%;
}
.prodgroupcards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2rem;
  /* height: 70%; */
  flex-basis: 65%;
  border-right: gray dashed 1px;
}
.prodgroupcard {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
}
.prodgroupcard:hover {
  cursor: pointer;
}
.productlink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.productlink:hover:active {
  font-weight: bold;
}
.selectedprodgroup {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding-left: 2rem;
}
.selectedprods {
  display: flex;
  flex-direction: column;
}
.selectedproddetails {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  padding-left: 2rem;
}
.prodaddcard {
  flex-basis: 30%;
}
.prodnameadmin:hover {
  text-decoration: underline;
  cursor: pointer;
}
.prodnameadmin:active:hover {
  font-weight: bold;
}
/* .tradercard:after {
  width: 16%;
  content: "";
  border: 1px solid transparent;
} */

.analysiscontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.analysisnav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 19%;
  width: 230px;
  background-color: rgb(65, 64, 73);
  /* background-color: black; */
  color: white;
  padding: 8rem 2rem;
  height: 100vh;
}
.analysisnav a {
  font-size: 0.9rem;
  color: white;
}
.analysisgridcontainer {
  /* margin-left: 10%; */
  display: flex;
  flex-basis: 90%;
  display: inline-block;
  width: 100%;
  position: relative;
  left: 230px;
}
.lysaleslist {
  overflow: auto;
  height: 90%;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  padding-bottom: 5rem;
}
.lystitlesection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lystitle {
  margin-bottom: 1rem;
}
.lysalesline {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lysalescolumn {
  display: flex;
  flex-basis: 20%;
}
.lysfig {
  text-align: right;
  width: 100px;
  display: inline-block;
  padding-right: 3rem;
}
.lysalesheader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-weight: bold;
  border-bottom: 1px solid rgb(68, 65, 162);
  margin-bottom: 0.5rem;
  background-color: white;
  /* color: red; */
}
.lysalesmonth {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  /* margin-top: 0.5rem; */
  top: 19px;
}
.lysalesdata li:nth-child(even) {
  background-color: rgb(245, 245, 245);
}
.lysalesline:hover {
  background-color: #ddeaf9;
}
.lysalesdata li:nth-child(even):hover {
  background-color: #ddeaf9;
}

.AVBTable {
  margin-top: 1.5rem;
  margin-left: 1rem;
  width: 95%;
  overflow-x: scroll;
  /* margin: 0 auto; */
}
.AVBTableHeaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  font-weight: 800;
  border-bottom: 1px solid gray;
}
.AVBTableHeaders li {
  display: flex;
  flex-basis: 10%;
  /* min-width: 50px; */
}
.AVBTableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.AVBTableRow li {
  display: flex;
  flex-basis: 10%;
}
.AVBTableTotals li {
  display: flex;
  flex-basis: 10%;
}
.AVBTableRow .AVBTableFig,
.AVBTableHeaders .AVBTableFig,
.AVBTableTotals .AVBTableFig {
  justify-content: flex-end;
}
.AVBTableRow .AVBFirstCol,
.AVBTableHeaders .AVBFirstCol,
.AVBTableTotals .AVBFirstCol {
  min-width: 90px;
}
.AVBCharts {
  display: flex;
  flex-direction: row;
}
.AVBChart {
  display: flex;
  width: 49%;
}
.AVBGroupEnd {
  margin-right: 5rem;
}
.AVBTitleArea {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.groupbuttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.groupbuttons button {
  border-radius: 1rem;
  padding: 0.3rem 1.3rem;
  margin: 0 0.3rem;
  background-color: white;
  border: 2px solid rgb(211, 210, 225);
}
.groupbuttons button:hover {
  cursor: pointer;
  background-color: rgb(211, 210, 225);
  color: rgb(68, 65, 162);
}
.groupbuttons button:active {
  background-color: rgb(68, 65, 162);
  color: white;
}
.groupbuttons .activegroupbutton {
  background-color: rgb(68, 65, 162);
  color: white;
}
.groupbuttons .AVBReset {
  background-color: rgb(216, 55, 74);
  color: white;
  border: none;
}
.groupbuttons .AVBReset:hover {
  background-color: rgb(226, 99, 114);
  color: white;
}
.groupbuttons .AVBReset:active {
  background-color: rgb(216, 55, 74);
  color: white;
}
.groupbuttons .AVBBackbutton {
  background-color: rgb(160, 182, 103);
  border: none;
  color: white;
  margin-right: 2rem;
}
.groupbuttons .AVBBackbutton:hover {
  background-color: rgb(205, 217, 174);
  border: none;
  color: white;
}
.groupbuttons .AVBBackbutton:active {
  background-color: rgb(160, 182, 103);
  border: none;
  color: white;
}
.AVBTableTotals {
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}
.AVBTableTotals .AVBFirstCol {
  min-width: 90px;
}

.logcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lognav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 19%;
  width: 230px;
  background-color: rgb(65, 64, 73);
  /* background-color: black; */
  color: white;
  padding: 8rem 2rem;
  height: 100vh;
}
.lognav a {
  font-size: 0.9rem;
  color: white;
}
.loggridcontainer {
  /* margin-left: 10%; */
  display: flex;
  /* flex-basis: 90%; */
  display: inline-block;
  width: 85%;
  position: relative;
  left: 230px;
  /* background-color: rgb(223, 223, 223); */
}
.scores {
  color: white;
  margin-top: 5rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.myoper {
  margin-bottom: 2rem;
}

.matchreporttitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.matchreporttitle h3 {
  position: fixed;
  top: 20px;
  width: 80%;
}

.matchreport {
  margin-top: 2rem;
  overflow: scroll;
  height: 90%;
}
.matchsearch {
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 0.2rem 1rem;
  border-radius: 0.2rem;
  border: 1px solid rgb(192, 192, 192);
}

.matchaccordion_button {
  /* background-color: #f4f4f4; */
  color: #444;
  cursor: pointer;
  padding: 5px 20px;
  width: 100%;
  text-align: left;
  border: none;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.matchaccordion_button:hover {
  background-color: rgb(227, 237, 250);
}
.matchaccordion:nth-child(even) {
  background-color: #f4f4f4;
}
.matchaccordion_button:before {
  display: inline-block;
  content: "";
  height: 5px;
  width: 5px;
  margin-right: 12px;
  margin-top: 5px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.matchaccordion_button[aria-expanded="true"]::before,
.matchaccordion_button[aria-selected="true"]::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.matchaccordion_button ul {
  display: flex;
  width: 100%;
}
.matchaccordion_button ul li {
  /* display: flex; */
  flex-basis: 15%;
  margin-right: 2rem;
}
.matchaccordion_button .prodli {
  flex-basis: 25%;
}
.matchpossales {
  display: flex;
  width: 50%;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.matchpossales li {
  display: flex;
  flex-direction: row;
  min-width: 100%;
}
.matchpossales li p {
  /* display: flex; */
  flex-basis: 30%;
}
.matchpossalesheader {
  border-bottom: 1px solid black;
}
.matchpossales .matchfig {
  text-align: right;
}

.inprogresslist {
  margin-top: 2rem;
  overflow: scroll;
  height: 90%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.inprogresslist li {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.inprogresslist li:nth-child(odd) {
  background-color: rgb(239, 237, 237);
}
.inprogresslist li p {
  flex-basis: 5%;
  margin: 0.4rem 0;
}
.inprog-center {
  text-align: center;
}
.inprogresslist li p input,
.inprogresslist li p select {
  width: 90%;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.inprogresslist li .inprogress-lrg-p {
  flex-basis: 10%;
  margin: 0.4rem 0;
}
.inprogresslist li .inprogress-med-p {
  flex-basis: 6%;
}
.inprogresslist li button {
  margin: 0.2rem 0;
  padding: 0.25rem 1rem;
  margin-left: 2rem;
  border-radius: 0.75rem;
  border: none;
  color: white;
  background-color: rgb(68, 65, 162);
}
.inprogresslist li button:hover {
  cursor: pointer;
}
.inprogresslist .inprogsavebtn {
  background-color: rgb(160, 182, 103);
}
.inprogresslist .inprogcancelbtn {
  background-color: rgb(146, 44, 46);
}
.inprogresslist li select {
  margin: 0.2rem 0;
  /* border: none; */
}

.inprogresslist-header {
  font-weight: 600;
  border-bottom: 1px solid gray;
}
.inprogresslist h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.trafficbuttons,
.operations {
  margin-top: 1rem;
  margin-left: 3rem;
}
.trafficbuttons button {
  margin-left: 1rem;
  padding: 0.4rem 2rem;
  border-radius: 1rem;
  border: none;
  background-color: rgb(149, 148, 199);
  color: white;
}
.trafficbuttons button:hover {
  cursor: pointer;
  background-color: rgb(68, 65, 162);
}
.trafficbuttons .activetrfbtn {
  background-color: rgb(68, 65, 162);
}
.opheader {
  display: flex;
  flex-direction: column;
  position: fixed;
  /* background-color: rgb(223, 223, 223); */
  min-width: 100%;
  z-index: 20;
  height: 115px;
  background-color: #dfdfdf;

  /* top: 7rem; */
}
.operations {
  margin-top: 8rem;
  background-color: rgb(223, 223, 223);
  position: relative;
}
.opsearchbar {
  margin-left: 3rem;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
}
.opsearchbar input {
  padding: 0.4rem 2rem;
  border-radius: 0.75rem;
  border: none;
  width: 300px;
}
.operationdetail {
  min-height: 70vh;
  background-color: white;
  min-width: 80vw;
  position: absolute;
  left: 120%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  top: 125px;
  border: solid rgb(68, 65, 162);
}
.trafficbuttons .traderOpButton {
  background-color: rgb(208, 173, 109);
}
.trafficbuttons .traderOpButton:hover {
  background-color: rgb(254, 146, 23);
}
.trafficbuttons .traderOpButtonActive {
  background-color: rgb(254, 146, 23);
}
.trafficbuttons .traderOpButtonActive:hover {
  background-color: rgb(254, 146, 23);
}

.operation {
  background-color: white;
  min-height: 9.5rem;
  margin-bottom: 1rem;
  width: 82vw;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  position: relative;
}
.operation:hover {
  background-color: rgb(238, 238, 238);
}
.opleftlabel:hover {
  cursor: pointer;
}
.opeditbutton {
  position: absolute;
  right: 0;
  margin: 0.5rem;
}
.opeditbutton:hover {
  color: rgb(23, 180, 23);
}
.editbuttons {
  position: absolute;
  right: 0;
  top: -20px;
  margin: 0.7rem;
  display: flex;
  flex-direction: row-reverse;
}
.opsavebutton {
  padding: 0.3rem 1rem;
  color: white;
  border-radius: 0.8rem;
  border: none;
  background-color: rgb(68, 65, 162);
  margin-left: 0.5rem;
}
.opsavebutton:hover {
  background-color: rgb(121, 118, 195);
  cursor: pointer;
}
.opcancelbutton {
  background-color: rgb(179, 40, 40);
}
.opcancelbutton:hover {
  background-color: rgb(212, 88, 88);
  cursor: pointer;
}
.optrader,
.optraffic {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0.5rem 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  color: rgb(159, 158, 158);
}
.optraffic {
  bottom: 20px;
}

.opleftlabel {
  min-height: 100%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: rgb(68, 65, 162);
  padding: 1.5rem 2rem;
  width: 15vw;
  min-width: 250px;
  color: white;
}
.opleftlabel h2 {
  text-transform: uppercase;
  font-size: 1rem;
}
.opleftlabel h3 {
  background-color: rgb(68, 65, 162);
  font-size: 0.8rem;
  font-weight: 400;
  z-index: 0;
}
.opleftlabel p {
  margin-top: 1.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.opnumberlabel {
  background-color: black;
  color: white;
  min-width: 9.5rem;
  max-width: 9.5rem;
  padding: 1rem 0;
  border-top: 2px solid white;
  -webkit-transform: rotate(270deg) translate(-22%, -39%);
          transform: rotate(270deg) translate(-22%, -39%);
  height: 85px;
}
.opnumberlabel p {
  display: flex;
  padding: 0;
  font-size: 0.8rem;
  /* font-weight: 600; */
  align-items: center;
  justify-content: center;
}
.optimeline {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  font-size: 0.7rem;
  width: 300px;
  justify-content: center;
}
.tlsegment {
  display: flex;
  flex-direction: column;
  line-height: 0.8rem;
  border-top: solid 1px black;
  padding-top: 0.5rem;
  position: relative;
  /* color:rgb(132, 196, 76) */
}
.tlsegment::before {
  content: "";
  min-height: 9px;
  min-width: 9px;
  background-color: black;
  border-radius: 50%;
  text-align: right; /* display: inline-block; */
  position: absolute;
  top: -5px;
  /* left: -5px; */
}
.tlnow::before {
  min-height: 12px;
  min-width: 12px;
  top: -7px;
  /* left: -8px; */
  background-color: rgb(72, 190, 72);
}
.tlfsegment {
  display: flex;
  flex-direction: column;
}
.opprogress {
  display: flex;
  flex-direction: column;
}
.oporigindestination {
  margin-top: 1.5rem;
  width: 300px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.oporigindestination ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.opchecklistsnapshot {
  margin-top: 1.5rem;
  margin-left: 4rem;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.opchecklist {
  margin-right: 1.2rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-right: dashed 1px black;
  /* margin-bottom: 1rem; */
  padding-right: 1.2rem;
}
.opchecklist .bookinginfo {
  padding: 0.1rem 0.7rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: solid 1px rgb(155, 155, 155);
  width: 152px;
}
.opchecklist .setbookinginfo {
  margin-left: 1.3rem;
}
.checklistitem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checklistitem p {
  margin-left: 0.5rem;
}
.opgreencheck {
  color: rgb(15, 177, 15);
}
.opsearchbar {
  display: flex;
  flex-direction: row;
}
.opsearchclearbtn {
  padding: 0.6rem 2rem;
  border: none;
  margin-left: 1rem;
  border-radius: 1rem;
  background-color: gray;
  color: white;
  background-color: rgb(68, 65, 162);
}
.opsearchclearbtn:hover {
  cursor: pointer;
  background-color: rgb(119, 117, 197);
}
.bookinginputs {
  display: flex;
  flex-direction: column;
}
.opincoterms {
  width: 50px;
  padding: 0.1rem 0.7rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: solid 1px rgb(155, 155, 155);
  margin-left: 0.5rem;
}
.incoitem {
  width: 125px;
  justify-content: space-between;
}
.opETDETAitem {
  width: 125px;
  justify-content: space-between;
  width: 90px;
  margin-left: 1.2rem;
}
.opETDETAitem1 {
  margin-left: 0;
}
.opETDETAitem p {
  margin-left: 0;
}
.opETDETA {
  margin-top: 0rem;
}
.bookingdateinfo {
  width: 110px;
  padding: 0.1rem 0.7rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: solid 1px rgb(155, 155, 155);
  margin-left: 0.5rem;
}
.opcontract {
  min-width: 120px;
}
.opleftlabelbottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.shipmentTypeIcon {
  width: 40px;
}
.iconopwithmsg {
}
.iconopwithmsg-wrapper {
  background-color: rgb(223, 82, 82);
  padding: 0.3rem;
  border-radius: 2rem;
  position: absolute;
  top: -15px;
}
.insuranceinfo {
  margin-bottom: 1rem;
}

@media screen and (max-width: 1440px) {
  .optimeline,
  .oporigindestination {
    width: 200px;
  }
  .loggridcontainer {
    left: 155px;
    /* width: 77vw; */
  }
  .lognav {
    flex-basis: auto;
    width: auto;
  }
}

.opnotes {
  max-height: 65%;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.opnoteinputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding-top: 1rem;
}
.opnoteinput {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  width: 82%;
  height: 80px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.opnotepost {
  padding: 0.4rem 1rem;
  background-color: rgb(68, 65, 162);
  border-radius: 0.5rem;
  border: none;
  color: white;
}
.opnotepost:hover {
  background-color: rgb(125, 123, 200);
  cursor: pointer;
}
.opnotegroup {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  width: 70%;
  align-self: flex-end;
}
.otherusermsg {
  align-self: flex-start;
}
.otherusermsg .opnote {
  background-color: rgb(227, 226, 235);
  color: rgb(68, 65, 162);
  border: solid 1px rgb(68, 65, 162);
}
.opnote {
  width: 100%;
  background-color: rgb(68, 65, 162);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.opnotemetadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}
.operationNotes {
  border: solid 1px rgb(68, 65, 162);
  border-radius: 0.7rem;
  padding: 1rem;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 38%;
  min-width: 38%;
}

.operationDetail {
  padding: 2rem;
  min-height: 600px;
  height: 55vh;
  padding: 1rem;
  margin: 1rem;
  /* border: 1px solid gray; */
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: solid 1px rgb(68, 65, 162); */
}
.opDetailHeader {
  width: 100%;
  /* background-color: red; */
  margin: 2rem;
}
.opDetailQSID {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}
.opDetailMIItem {
  display: flex;
  flex-direction: row;
  width: 300px;
}
.opDetailSupplyside {
  display: flex;
  flex-basis: 27%;
  min-width: 27%;
  flex-direction: column;
}
.opDetailDemandside {
  display: flex;
  flex-basis: 27%;
  min-width: 27%;
  flex-direction: column;
}
.opDetailItem {
  display: flex;
  flex-direction: row;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.opDetailItem input {
  padding: 0.2rem 1rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  border: solid 1px rgb(183, 183, 183);
  width: 200px;
}
.opDItext {
  text-align: left;
  width: 200px;
  padding-left: 0.5rem;
}

@media screen and (max-width: 1440px) {
  .operationDetail {
    min-height: 400px;
  }
}

.chatcontainer {
  margin: 3rem 3rem;
}
.chatmain {
  display: flex;
  flex-direction: column;
}
.chatbody {
  min-height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.chatfooter {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
.chatfooter input {
  width: 60%;
  padding: 0.4rem 1rem;
}

.budgetcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
  overflow-y: clip;
}
.budgetnav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  width: 130px;
  background-color: rgb(65, 64, 73);
  /* background-color: black; */
  color: white;
  padding: 8rem 2rem;
  height: 100vh;
}
.budgetgridcontainer {
  /* margin-left: 10%; */
  display: flex;
  flex-basis: 100%;
  display: inline-block;

  /* width: 150%; */
  position: relative;
  left: 130px;
  background-color: white;
  min-height: 100vh;
  margin-top: 2rem;
  padding-left: 2rem;
  height: auto;
  /* position: fixed; */
}
.addprodgroup {
  position: relative;
  margin-right: 1rem;
  /* padding-bottom: rem; */
  /* border-bottom: dashed 1px gray; */
}
.addprodbutton,
.cancelprodbutton {
  margin: 0.2rem 0;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
  border: none;
  color: white;
  background-color: rgb(68, 65, 162);
  position: relative;
}
.cancelprodbutton {
  background-color: white;
  border: solid 1px rgb(68, 65, 162);
  color: rgb(68, 65, 162);
}
.addprodbutton:hover {
  cursor: pointer;
  background-color: rgb(99, 95, 193);
}

.cancelprodbutton:hover {
  cursor: pointer;
  background-color: rgb(223, 223, 233);
}
.addprodpane {
  position: absolute;
  top: 25px;
  height: 300px;
  width: 230px;
  border: 1px solid rgb(68, 65, 162);
  border-radius: 0.75rem;
  background-color: white;
  z-index: 10;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.addprodpane ul {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  overflow-y: scroll;
  margin: 0.5rem 0;
  min-height: 75%;
}
.addprodcanceladd {
  display: flex;
  justify-content: space-between;
}
.addprodcanceladd button {
  display: flex;
  flex-basis: 45%;
}
.addprodrow {
  display: flex;
  flex-direction: row;
}
.addprodrow:hover,
.addprodrow input:hover,
.addprodrow label:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}
.addprodrow input {
  margin-right: 0.5rem;
}
.budgetfilterbuttons {
  padding-top: 0.5rem;
}
.budgetfilterbutton {
  margin: 0.2rem 0;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: rgb(68, 65, 162) solid 1px;
  color: rgb(68, 65, 162);
  background-color: white;
  width: 150px;
  margin-right: 0.5rem;
}
.budgetfilterbutton:hover {
  cursor: pointer;
  background-color: rgb(150, 148, 207);
  color: white;
}
.budgetfilterbutton:active {
  background-color: rgb(68, 65, 162);
  color: white;
  border: none;
}
.budgetfilterbuttonactive {
  background-color: rgb(68, 65, 162);
  color: white;
  border: none;
}
.pgroupfbtns {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: dashed 1px gray;
}
.pgroupfbtns p {
  padding-right: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.pnamefbtns {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: dashed 1px gray;
}
.pnamefbtns p {
  padding-right: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.pnamebutton {
  margin: 0.2rem 0;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: rgb(160, 182, 103) solid 1px;
  color: rgb(68, 65, 162);
  background-color: white;
  width: 150px;
  margin-right: 0.5rem;
}
.pnamebutton:hover {
  cursor: pointer;
  background-color: rgb(191, 214, 134);
  color: white;
}
.pnamebutton:active {
  background-color: rgb(160, 182, 103);
  color: white;
  border: none;
}
.pnamebuttonactive {
  background-color: rgb(160, 182, 103);
  color: white;
}
.bdgtpnametable {
  margin-top: 1rem;
  margin-right: 2rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.bdgtpnametable table {
  border-collapse: collapse;
}
.bdgtpnametable td {
  border: 1px solid gray;
  /* padding: 0.15rem 0; */
  margin: 0;
}
.bdgtpnametable td input {
  margin: 0;
  border: none;
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtpnametable .countrycol {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-width: 115px;
}
.bdgtpnametabletitle {
  display: flex;
  position: relative;
}
.bdgtpnametabletitle h3 {
  width: auto;
  margin-right: 1rem;
}
.budgettables {
  display: flex;
  flex-direction: column;
}
.bdgtdatacol {
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtpnametable .bdgtdatacol input {
  width: 60px;
  text-align: right;
  padding-right: 0;
}
.bdgtpnametable .bdgtregioncol {
  padding-left: 0.75rem;
  background-color: rgb(219, 219, 219);
}
.bdgtpnametable thead td {
  background-color: black;
  color: white;
}
.budgettablesandresponse {
  display: flex;
  flex-direction: column;
}
.bdgtresponsemsg {
  color: green;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: flex;
  width: 500px;
}
.bdgtcountrytotals {
  font-weight: 600;
  text-align: right;
  padding-right: 0.5rem;
  min-width: 70px;
}
.bdgtpnametable .bdgtregioncolttl {
  padding-left: 0.75rem;
  background-color: rgb(219, 219, 219);
  text-align: right;
  font-weight: 600;
  padding-right: 0.5rem;
}
.bdgttotal {
  background-color: black;
  color: white;
  padding-left: 0.75rem;
}
.bdgttotalqty {
  background-color: black;
  color: white;
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtctyrowadd {
  margin-right: 0.25rem;
}
.bdgtctyrowadd:hover {
  cursor: pointer;
}
.bdgtpnametable h3 {
  z-index: 0;
}
.bdgtregionrow {
  position: relative;
}
.bdgtselectcty {
  position: absolute;
  min-width: 230px;
  height: 300px;
  left: 150px;
  z-index: 10;
  top: 0px;
  border: 1px solid rgb(68, 65, 162);
  border-radius: 0.75rem;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bdgtselectallcty {
  position: absolute;
  min-width: 230px;
  height: 300px;
  z-index: 10;
  top: 18px;
  border: 1px solid rgb(68, 65, 162);
  border-radius: 0.75rem;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.showbdgtpane {
  display: inline-block;
}
.hidebdgtpane {
  display: none;
}
.bdgtselectctycty {
  margin: 0.5rem 0;
  max-height: 200px;
  overflow-y: scroll;
}
.addctyrow {
  display: flex;
  flex-direction: row;
}
.addctyrow:hover,
.addctyrow input:hover,
.addctyrow label:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}
.addctyrow label {
  margin-left: 0.25rem;
}
.bdgtctydelete {
  margin-left: 0.5rem;
  opacity: 0.3;
}
.bdgtctydeletebtn {
  border: none;
  padding: 0.05rem 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.5rem;
  margin-left: 0.25rem;
  background-color: rgb(68, 65, 162);
  color: white;
  font-size: 0.7rem;
}
.budgetprepdata {
  height: 54vh;
  display: flex;
  justify-content: space-between;
  /* overflow-y: scroll; */
}
.bdgtleftbottompanel {
  height: 54vh;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
}
.budgetbyprod {
  width: 580px;
  /* background-color: rgb(247, 247, 247); */
  border-left: 1px dashed rgb(68, 65, 162);
  padding: 1rem;
  padding-left: 2rem;

  /* height: 600px; */
  display: flex;
  flex-direction: column;
}
.budgetbyprod li {
  display: flex;
  font-size: 0.85rem;
}
.lyearfigures {
  margin-top: 1rem;
}
.lyearfigures h3 {
  font-family: "Roboto", sans-serif;
  font-size: 0.936rem;
  position: relative;
}
.lyearfigures table {
  border-collapse: collapse;
}
.lyearfigures table td {
  border: 1px solid gray;
}
.lyearhead {
  color: white;
  background-color: rgb(160, 182, 103);
}
.lyearregrow {
  background-color: rgb(219, 219, 219);
  /* font-weight: 600; */
}
.lyearregrowdata {
  background-color: rgb(219, 219, 219);
  text-align: right;
  font-size: 0.8rem;
  width: 60px;
  padding-right: 0.5rem;
  font-weight: 800;
}
.lyearcountrycol {
  padding-left: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  width: 110px;
}
.lyeardatah {
  text-align: right;
  font-size: 0.8rem;
  padding-right: 0.25rem;
}
.lyeardata {
  text-align: right;
  font-size: 0.8rem;
  width: 60px;
  padding-right: 0.5rem;
}
.lyearcountrycolttl {
  padding-left: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  width: 110px;
  background-color: black;
  color: white;
}
.lyeardatattl {
  text-align: right;
  font-size: 0.8rem;
  width: 60px;
  padding-right: 0.5rem;
  background-color: black;
  color: white;
}
.lyeartable {
  margin-bottom: 1rem;
}
.bdgttotals {
  border-bottom: 1px solid gray;
}
.bdgttotalsul {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 1rem;
  font-family: "Helvetica Neue", sans-serif;
}
.bdgttyearfig {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.bdgttyearname {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.bdgtlyearfigs {
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  width: 180px;
}
.bdgttitle {
  margin-right: 1rem;
}
.bdgttitles {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: solid 2px gray;
}
.bdgtpnametable .bdgtcolseparation {
  width: 15px;
  border: none;
  background-color: white;
}
.bdgtctyeconomics {
  width: 60px;
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtpnametable .bdgtctyeconomics input {
  width: 60px;
  text-align: right;
  padding-right: 0;
  /* padding-right: 0.5rem; */
}
.pgroupfbtns .bdgtbtntitle {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}
.pnamefbtns .bdgtbtntitle {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
}
.budgetbyprod h4 {
  margin-bottom: 0.5rem;
}
.bdgtsummarybuttons1,
.bdgtsummarybuttons2,
.bdgtsummarybuttons {
  display: flex;
  /* padding: 0.5rem 0; */
  border-bottom: 1px gray dashed;
  width: 550px;
  align-items: center;
}
.bdgtsummarybuttons {
  margin-top: 1rem;
  border: none;
}
.bdgtsummarybuttons1 p,
.bdgtsummarybuttons2 p,
.bdgtsummarybuttons p {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.bdgtsummarybuttons1 button,
.bdgtsummarybuttons2 button,
.bdgtsummarybuttons button {
  width: 98px;
  /* margin-left: 0.5rem; */
  /* border-radius: 0.75rem; */
  padding: 0.25rem 0rem;
  background-color: white;
  border: none;
  font-size: 0.8rem;
  font-family: "Helvetica Neue", sans-serif;
}
.bdgtsummarybuttons1 button,
.bdgtsummarybuttons button {
  border-left: solid 1px rgb(68, 65, 162);
  border-right: solid 1px rgb(68, 65, 162);
}
.bdgtsummarybuttons button {
  padding: 0.25rem 0.5rem;
  width: 100px;
}
.bdgtsummarybuttons2 button {
  border-left: solid 1px rgb(160, 182, 103);
  border-right: solid 1px rgb(160, 182, 103);
}
.bdgtsummarybuttons1 .bdgtlvl1active {
  background-color: rgb(68, 65, 162);
  color: white;
}
.bdgtsummarybuttons2 .bdgtlvl2active {
  background-color: rgb(160, 182, 103);
  color: white;
}
.bdgtsummarybuttons .bdgtlvl3active {
  background-color: black;
  color: white;
}
.bdgtsummarybuttons1 button:hover {
  cursor: pointer;
  background-color: rgb(190, 189, 229);
}
.bdgtsummarybuttons2 button:hover {
  cursor: pointer;
  background-color: rgb(203, 215, 171);
}
.bdgtsummarybuttons button:hover {
  cursor: pointer;
  background-color: rgb(220, 220, 220);
  color: black;
}
.bdgtsummarybuttons1 button:active {
  background-color: rgb(68, 65, 162);
  color: white;
}
.bdgtsummarybuttons2 button:active {
  background-color: rgb(160, 182, 103);
  color: white;
}
.bdgtsummarybuttons button:active {
  background-color: black;
  color: white;
}
.bdgtsummarytable {
  margin-top: 1rem;
  width: 550px;
}
.bdgtsummarydata {
  height: 50vh;
  overflow-y: scroll;
  margin-right: 0;
  /* position: relative; */
}
.stblrow {
  display: flex;
  width: 100%;
  /* padding-left: 1rem; */
  font-family: "Helvetica Neue", sans-serif;
  font-size: 0.9rem;
}
.stblrow p {
  display: flex;
  /* flex-basis: 16%;
 */
  width: 88px;
}
.stblrow .stblcollarge {
  display: flex;
  /* flex-basis: 20%;
   */
  width: 76px;
}
.bsummaccordion_button {
  color: #444;
  cursor: pointer;
  padding: 2px 0;
  /* width: 500px; */
  text-align: left;
  border: none;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  /* position: sticky;
  top: 0; */
}
.bsummaccordion_button:before {
  display: inline-block;
  content: "";
  height: 5px;
  width: 5px;
  left: -5px;
  margin-right: 12px;
  margin-top: 5px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.bsummaccordion_button[aria-expanded="true"]::before,
.bsummaccordion_button[aria-selected="true"]::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.bsummaccordion_button:hover {
  background-color: rgb(245, 245, 245);
}
.bdgtacc {
  border: none;
}
.stblheader {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid gray;
}
.stblfig {
  text-align: right;
  justify-content: right;
}
.bdgtaccpanel {
  padding: 0.5rem 0;
  border-top: 1px gray dashed;
  border-bottom: 1px gray dashed;
  margin-left: 15px;
}
.stblfooter {
  margin-bottom: 0.5rem;
  border-top: 1px solid gray;
  /* justify-content: right; */
}
.bdgtexport:hover {
  cursor: pointer;
}
.stblfooter .stblcollarge,
.stblheader .stblcollarge {
  width: 96px;
}
.stbltotal {
  font-weight: 600;
}
.budgetselectfilebutton {
  border: none;
  /* border-radius: 0.75rem; */
  padding: 0.25rem 1rem;
  margin-left: 25rem;
  background-color: black;
  color: white;
  margin-right: 1rem;
  font-size: 0.8rem;
}
.budgetselectfilebutton:hover {
  cursor: pointer;
  background-color: rgb(107, 107, 107);
}
.bdgtloadfileinput {
  min-width: 400px;
}
.bdgtloadbtn {
  border: none;
  border-radius: 0.75rem;
  padding: 0.25rem 1rem;
  margin-left: 1rem;
  background-color: rgb(161, 161, 161);
  color: white;
  z-index: 5;
}
.bdgtloadbtn:hover {
  cursor: pointer;
  background-color: rgb(97, 97, 97);
}
input[type="file"] {
  display: none;
}
.showloadbutton {
  display: inline;
}
.hideloadbutton {
  display: none;
}
.loadmsg {
  display: inline;
  margin-left: 1rem;
  color: rgb(24, 139, 24);
}

.contextMenu {
  position: absolute;
  width: 270px;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 10;
  border: solid 1px black;
}
.contextMenu ul {
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  list-style: none;
}
.contextMenu ul li {
  /* padding: 18px 12px; */
  background-color: white;
}
.bdgtpnametable .contextMenu ul li textarea {
  width: 100%;
  height: 60px;
  border: solid 1px;
  text-align: left;
}
.contextMenu ul li button {
  margin: 0.5rem;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
  border: none;
  background-color: rgb(68, 65, 162);
  color: white;
}
.contextMenu ul li button:hover {
  cursor: pointer;
  background-color: rgb(121, 119, 190);
}
.contextMenu ul li button:active {
  background-color: rgb(68, 65, 162);
}

.cellwithcomment {
  background-color: rgb(241, 228, 221);
}
.contextMenu .bdgtcomment {
  color: black;
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 0.75rem;
}
.singlecomment {
  display: flex;
  flex-direction: row;
  width: 100%;
  vertical-align: bottom;
  justify-content: space-between;
}
.commenticon {
  color: red;
}
.commenticon:hover {
  cursor: pointer;
  color: rgb(159, 20, 20);
}
.bdgtprodtotals {
  width: 69px;
  text-align: right;
  padding-right: 7px;
  background-color: rgb(160, 182, 103);
}
.bdgtctyttl {
  background-color: rgb(160, 182, 103);
}
/* hover */
/* .contextMenu ul li:hover {
  cursor: pointer;
  background-color: #000000;
  color: white;
} */

.contextMenu {
  position: absolute;
  width: 270px;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 10;
  border: solid 1px black;
}
.contextMenu ul {
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  list-style: none;
}
.contextMenu ul li {
  /* padding: 18px 12px; */
  background-color: white;
}
.bdgtpnametable .contextMenu ul li textarea {
  width: 100%;
  height: 60px;
  border: solid 1px;
  text-align: left;
}
.contextMenu ul li button {
  margin: 0.5rem;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
  border: none;
  background-color: rgb(68, 65, 162);
  color: white;
}
.contextMenu ul li button:hover {
  cursor: pointer;
  background-color: rgb(121, 119, 190);
}
.contextMenu ul li button:active {
  background-color: rgb(68, 65, 162);
}

.cellwithcomment {
  background-color: rgb(241, 228, 221);
}
.contextMenu .bdgtcomment {
  color: black;
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 0.75rem;
}
.singlecomment {
  display: flex;
  flex-direction: row;
  width: 100%;
  vertical-align: bottom;
  justify-content: space-between;
}
.commenticon {
  color: red;
}
.commenticon:hover {
  cursor: pointer;
  color: rgb(159, 20, 20);
}
.bdgtprodtotals {
  width: 69px;
  text-align: right;
  padding-right: 7px;
  background-color: rgb(160, 182, 103);
}
.bdgtctyttl {
  background-color: rgb(160, 182, 103);
}
/* hover */
/* .contextMenu ul li:hover {
  cursor: pointer;
  background-color: #000000;
  color: white;
} */

