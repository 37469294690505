.logcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lognav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 19%;
  width: 230px;
  background-color: rgb(65, 64, 73);
  /* background-color: black; */
  color: white;
  padding: 8rem 2rem;
  height: 100vh;
}
.lognav a {
  font-size: 0.9rem;
  color: white;
}
.loggridcontainer {
  /* margin-left: 10%; */
  display: flex;
  /* flex-basis: 90%; */
  display: inline-block;
  width: 85%;
  position: relative;
  left: 230px;
  /* background-color: rgb(223, 223, 223); */
}
.scores {
  color: white;
  margin-top: 5rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.myoper {
  margin-bottom: 2rem;
}
