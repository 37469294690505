.operation {
  background-color: white;
  min-height: 9.5rem;
  margin-bottom: 1rem;
  width: 82vw;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  position: relative;
}
.operation:hover {
  background-color: rgb(238, 238, 238);
}
.opleftlabel:hover {
  cursor: pointer;
}
.opeditbutton {
  position: absolute;
  right: 0;
  margin: 0.5rem;
}
.opeditbutton:hover {
  color: rgb(23, 180, 23);
}
.editbuttons {
  position: absolute;
  right: 0;
  top: -20px;
  margin: 0.7rem;
  display: flex;
  flex-direction: row-reverse;
}
.opsavebutton {
  padding: 0.3rem 1rem;
  color: white;
  border-radius: 0.8rem;
  border: none;
  background-color: rgb(68, 65, 162);
  margin-left: 0.5rem;
}
.opsavebutton:hover {
  background-color: rgb(121, 118, 195);
  cursor: pointer;
}
.opcancelbutton {
  background-color: rgb(179, 40, 40);
}
.opcancelbutton:hover {
  background-color: rgb(212, 88, 88);
  cursor: pointer;
}
.optrader,
.optraffic {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0.5rem 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  color: rgb(159, 158, 158);
}
.optraffic {
  bottom: 20px;
}

.opleftlabel {
  min-height: 100%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: rgb(68, 65, 162);
  padding: 1.5rem 2rem;
  width: 15vw;
  min-width: 250px;
  color: white;
}
.opleftlabel h2 {
  text-transform: uppercase;
  font-size: 1rem;
}
.opleftlabel h3 {
  background-color: rgb(68, 65, 162);
  font-size: 0.8rem;
  font-weight: 400;
  z-index: 0;
}
.opleftlabel p {
  margin-top: 1.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.opnumberlabel {
  background-color: black;
  color: white;
  min-width: 9.5rem;
  max-width: 9.5rem;
  padding: 1rem 0;
  border-top: 2px solid white;
  transform: rotate(270deg) translate(-22%, -39%);
  height: 85px;
}
.opnumberlabel p {
  display: flex;
  padding: 0;
  font-size: 0.8rem;
  /* font-weight: 600; */
  align-items: center;
  justify-content: center;
}
.optimeline {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  font-size: 0.7rem;
  width: 300px;
  justify-content: center;
}
.tlsegment {
  display: flex;
  flex-direction: column;
  line-height: 0.8rem;
  border-top: solid 1px black;
  padding-top: 0.5rem;
  position: relative;
  /* color:rgb(132, 196, 76) */
}
.tlsegment::before {
  content: "";
  min-height: 9px;
  min-width: 9px;
  background-color: black;
  border-radius: 50%;
  text-align: right; /* display: inline-block; */
  position: absolute;
  top: -5px;
  /* left: -5px; */
}
.tlnow::before {
  min-height: 12px;
  min-width: 12px;
  top: -7px;
  /* left: -8px; */
  background-color: rgb(72, 190, 72);
}
.tlfsegment {
  display: flex;
  flex-direction: column;
}
.opprogress {
  display: flex;
  flex-direction: column;
}
.oporigindestination {
  margin-top: 1.5rem;
  width: 300px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.oporigindestination ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.opchecklistsnapshot {
  margin-top: 1.5rem;
  margin-left: 4rem;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.opchecklist {
  margin-right: 1.2rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-right: dashed 1px black;
  /* margin-bottom: 1rem; */
  padding-right: 1.2rem;
}
.opchecklist .bookinginfo {
  padding: 0.1rem 0.7rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: solid 1px rgb(155, 155, 155);
  width: 152px;
}
.opchecklist .setbookinginfo {
  margin-left: 1.3rem;
}
.checklistitem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checklistitem p {
  margin-left: 0.5rem;
}
.opgreencheck {
  color: rgb(15, 177, 15);
}
.opsearchbar {
  display: flex;
  flex-direction: row;
}
.opsearchclearbtn {
  padding: 0.6rem 2rem;
  border: none;
  margin-left: 1rem;
  border-radius: 1rem;
  background-color: gray;
  color: white;
  background-color: rgb(68, 65, 162);
}
.opsearchclearbtn:hover {
  cursor: pointer;
  background-color: rgb(119, 117, 197);
}
.bookinginputs {
  display: flex;
  flex-direction: column;
}
.opincoterms {
  width: 50px;
  padding: 0.1rem 0.7rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: solid 1px rgb(155, 155, 155);
  margin-left: 0.5rem;
}
.incoitem {
  width: 125px;
  justify-content: space-between;
}
.opETDETAitem {
  width: 125px;
  justify-content: space-between;
  width: 90px;
  margin-left: 1.2rem;
}
.opETDETAitem1 {
  margin-left: 0;
}
.opETDETAitem p {
  margin-left: 0;
}
.opETDETA {
  margin-top: 0rem;
}
.bookingdateinfo {
  width: 110px;
  padding: 0.1rem 0.7rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: solid 1px rgb(155, 155, 155);
  margin-left: 0.5rem;
}
.opcontract {
  min-width: 120px;
}
.opleftlabelbottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.shipmentTypeIcon {
  width: 40px;
}
.iconopwithmsg {
}
.iconopwithmsg-wrapper {
  background-color: rgb(223, 82, 82);
  padding: 0.3rem;
  border-radius: 2rem;
  position: absolute;
  top: -15px;
}
.insuranceinfo {
  margin-bottom: 1rem;
}

@media screen and (max-width: 1440px) {
  .optimeline,
  .oporigindestination {
    width: 200px;
  }
  .loggridcontainer {
    left: 155px;
    /* width: 77vw; */
  }
  .lognav {
    flex-basis: auto;
    width: auto;
  }
}
