.QSmodal {
  height: 110%;
}
.QSmodal .modal-main {
  width: 90%;
  height: 80%;
}
.QSmodal h2 {
  padding-top: 0.5rem;
}
.QSModalForm {
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  width: 100%;
}
.QSModalForm fieldset {
  border: solid 1px black;
  border-style: groove;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.QSModalForm section {
  padding: 1rem;
}
.QSModalForm .form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding-bottom: 1rem;
  flex-wrap: wrap;
}

.QSModalForm input {
  flex: 1;
  flex-basis: 40%;
  margin-right: 1rem;
  min-width: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.QSModalForm label {
  flex: 1;
  flex-basis: 15%;
  width: 100%;
  /* width: 0; */
}
.QSModalForm legend {
  /* background-color: white; */
  font-weight: 900;
  color: rgb(68, 65, 162);
  font-size: 1rem;
}
.QSModalForm p {
  padding: 0;
}
.QSModalForm .shipmentType label {
  padding-right: 1rem;
}
.QSModalForm .shipmentType {
  margin-bottom: 0.6rem;
}
.QSModalForm .searchresults {
  position: absolute;
  flex: 4;
  top: -20px;
  left: -15px;
  width: 300px;
  background-color: white;
  border: solid 1px black;
  max-height: 30vh;
  overflow-y: auto;
  padding-left: 0.3rem;
  padding-right: 0;
}
.QSModalForm .searchresults li {
  width: 100%;
}
.QSModalForm .presearchresults {
  flex: 1;
  flex-basis: 20%;
}
.QSModalForm .QSsearchresults-container {
  position: relative;
  display: flex;
}
#editQS-1 {
  flex: 1;
  flex-basis: 12%;
  min-height: 100%;
}
#editQS-2 {
  flex: 1;
  flex-basis: 12%;
  min-height: 100%;
}
#editQS-3 {
  flex-basis: 60%;
  flex: 4;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
#editQS-3 fieldset {
  display: flex;
  width: 100%;
}
.QSedit-buttons {
  display: flex;
  flex-direction: row;
}
.QSedit-buttons button {
  margin: 0 0.2rem;
}
#editQS-3-col1 {
  flex: 1;
  flex-basis: 50%;
}
#editQS-3-col1 fieldset {
  display: flex;
  flex-direction: column;
}
#editQS-3-col2 {
  flex: 1;
  flex-basis: 50%;
}
#editQS-3-col2 fieldset {
  display: flex;
  flex-direction: column;
}
.WGPSelect2 {
  overflow: hidden;
  white-space: nowrap;
  width: 140px;
  margin-left: 1rem;
}
.WGPSelect2 option {
  overflow: hidden;
  white-space: nowrap;
}
.QSeditmodaltitle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  /* margin-top: 1rem; */
}
.QSeditmodaltitle button {
  width: fit-content;
  padding: 0.2rem 0.5rem;
  margin: 0.5rem 0.9rem 0 0.9rem;
}
.QSeditmodalsearch {
  /* margin: 0 0rem; */
}
