.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.modal-main {
  position: fixed;
  background: white;
  width: 30%;
  height: 70vh;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  box-shadow: 3px 3px 30px rgb(0, 0, 0);
  border-radius: 3rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.positionModalForm .form-group input {
  flex: 4 1;
  flex-basis: 70%;
  /* width: 50%; */
  margin-left: 1rem;
}
.positionModalForm textarea {
  /* margin-left: 1rem; */
  margin-top: 0.5rem;
  width: 100%;
  height: 15vh;
}
.positionModalForm .form-group {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding-bottom: 1rem;
}
.positionModalForm h2 {
  margin-bottom: 0.5rem;
}
.positionModalForm .form-group label {
  flex: 1 1;
  /* flex: none; */
  /* display: block; */
  /* width: 130px; */
  flex-basis: 20%;
}
.positionModalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.positionModalForm .form-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.positionModalForm ul {
  max-height: 30vh;
  overflow-y: auto;
}
.positionModalForm .presearchresults {
  flex: 1;
  flex-basis: 15%;
}
.positionModalForm .QSsearchresults-container {
  flex: 4;
  flex-basis: 40%;
}
