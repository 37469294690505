.uspositionreport {
  overflow: auto;
  height: 100%;
}
#uspositionreporttable {
  margin-top: 0.5rem;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
}
#uspositionreporttable td {
  padding-right: 3.5rem;
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
  font-family: "Roboto", sans-serif;
}
#uspositionreporttable th {
  padding-right: 3rem;
  font-size: 0.9rem;
  border: none;
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
  top: 0px;
}
#uspositionreporttable tr:hover {
  background-color: #ddeaf9;
  cursor: pointer;
}
#uspositionreporttable .totals {
  border-top: 1px solid black;
  height: 2rem;
  vertical-align: top;
}
.usprodgroup {
  background-color: rgb(68, 65, 162);
  color: white;
}
.mktpriceupdate {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mktpriceupdatemenu {
  position: absolute;
  /* right: 100%; */
  top: 20px;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid rgb(68, 65, 162);
  z-index: 10;
}
.mktpriceupdatemenu button {
  border-radius: 1rem;
  padding: 0.25rem 2rem;
  background-color: rgb(160, 182, 103);
  color: white;
  margin-top: 0.5rem;
  border: none;
}
.mktpriceupdatemenu button:hover {
  cursor: pointer;
  background-color: rgb(179, 197, 133);
}
