.positionreport {
  overflow: auto;
  height: 100%;
}
.positionreporttitle {
  font-family: "Roboto", sans-serif;
  color: rgb(68, 65, 162);
  background-color: white;
  z-index: 90;
}
#positionreporttable {
  margin-top: 0.5rem;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
}

#positionreporttable td {
  padding-right: 3.5rem;
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
  font-family: "Roboto", sans-serif;

  /* background-color: white; */
}
#positionreporttable th {
  padding-right: 3rem;
  font-size: 0.9rem;
  border: none;
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
  top: 0px;
}

.fig {
  text-align: right;
}
#positionreporttable tr:hover {
  background-color: #ddeaf9;
  cursor: pointer;
}
#positionreporttable .totals {
  border-top: 1px solid black;
  height: 2rem;
  vertical-align: top;
}
.prodgroup {
  background-color: rgb(160, 182, 103);
  color: white;
}
.product {
  /* background-color: rgb(228, 236, 204); */
}
.positionreporttitleline {
  display: flex;
  position: sticky;
  top: 0;
}
/* .postooltip {
  border: solid 1px rgb(68, 65, 162) !important;
  font-family: "Roboto", sans-serif !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
  opacity: 1 !important;
} */

.postooltipsource {
  position: relative;
}
.postooltip {
  position: absolute;
  z-index: 50;
  background-color: white;
  border: rgb(68, 65, 162) 1px solid;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 900px;
  top: 19px;
  left: 80%;
}
.posmatchitem {
  display: flex;
  flex-direction: row;
}
.posmatchitem p,
.posmatchitem h4 {
  flex-basis: 20%;
}
.react-tabs__tab-panel--selected {
  overflow: auto;
  height: 90%;
}
.react-tabs {
  height: 100%;
}
