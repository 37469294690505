.sunburst {
  padding-top: 6rem;
  display: flex;
  width: 1000px;
}
#Sunburst {
  display: flex;
  transform: scale(0.7);
}
#Sunburst-svg {
  /* transform: scale(0.7); */
  display: flex;
  /* transform: translateX(-50%); */
}
