td[id^="quantity"] {
  text-align: right;
}
td[id^="FOB"] {
  text-align: right;
}
.editbutton {
  cursor: pointer;
  background-color: transparent;
  color: rgb(160, 182, 103);
  padding: 0.1rem 0.5rem;
  border: 1px solid rgb(68, 65, 162);
  align-self: center;
  border-radius: 1rem;
  margin-left: 0.5rem;
  margin-top: 2px;
  /* font-family: "Roboto", sans-serif; */
  /* font-size: 1rem; */
}
.editbutton:hover {
  color: white;
  background-color: rgb(68, 65, 162);
}

table thead tr:nth-child(1) th {
  /* background: RED; */
  position: sticky;
  top: 18px;
  z-index: 10;
  /* background-color: blue; */
}
.tablesorter thead td {
  position: sticky;
  top: 41px;
  z-index: 11;
  /* background-color: blue; */
}

.tablesorter .header {
  border-bottom: none;
  background-color: rgb(160, 182, 103);
  color: white;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
}
.tablesorter .lastheader {
  background: #f5f5f5;
}
.filter-WGP input {
  width: 70px;
}
.filter-abbreviation input {
  width: 110px;
}
.filter-packaging input {
  width: 70px;
}
.filter-Start input {
  width: 90px;
}
.filter-End input {
  width: 90px;
}
.filter-FOB input {
  width: 90px;
}
.filter-quantity input {
  width: 90px;
}
.filter-year input {
  width: 90px;
}
