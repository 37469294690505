.trafficbuttons,
.operations {
  margin-top: 1rem;
  margin-left: 3rem;
}
.trafficbuttons button {
  margin-left: 1rem;
  padding: 0.4rem 2rem;
  border-radius: 1rem;
  border: none;
  background-color: rgb(149, 148, 199);
  color: white;
}
.trafficbuttons button:hover {
  cursor: pointer;
  background-color: rgb(68, 65, 162);
}
.trafficbuttons .activetrfbtn {
  background-color: rgb(68, 65, 162);
}
.opheader {
  display: flex;
  flex-direction: column;
  position: fixed;
  /* background-color: rgb(223, 223, 223); */
  min-width: 100%;
  z-index: 20;
  height: 115px;
  background-color: #dfdfdf;

  /* top: 7rem; */
}
.operations {
  margin-top: 8rem;
  background-color: rgb(223, 223, 223);
  position: relative;
}
.opsearchbar {
  margin-left: 3rem;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
}
.opsearchbar input {
  padding: 0.4rem 2rem;
  border-radius: 0.75rem;
  border: none;
  width: 300px;
}
.operationdetail {
  min-height: 70vh;
  background-color: white;
  min-width: 80vw;
  position: absolute;
  left: 120%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  top: 125px;
  border: solid rgb(68, 65, 162);
}
.trafficbuttons .traderOpButton {
  background-color: rgb(208, 173, 109);
}
.trafficbuttons .traderOpButton:hover {
  background-color: rgb(254, 146, 23);
}
.trafficbuttons .traderOpButtonActive {
  background-color: rgb(254, 146, 23);
}
.trafficbuttons .traderOpButtonActive:hover {
  background-color: rgb(254, 146, 23);
}
