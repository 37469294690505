.matchreporttitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.matchreporttitle h3 {
  position: fixed;
  top: 20px;
  width: 80%;
}
