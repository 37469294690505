.AddProdModal {
  height: 110%;
}
.AddProdModal .modal-main {
  width: 80%;
  height: 70%;
}
.AddProdModal .addproduct-form {
  display: flex;
  flex-direction: row;
  height: 90%;
}
.addprod-1 {
  flex: 1;
  border-right: 1px dashed gray;
  min-height: 100%;
}
.addprod-1 p {
  padding-bottom: 0;
}
.addprod-2 {
  flex: 1;
  border-right: 1px dashed gray;
}
.addprod-3 {
  flex: 1;
  /* border: 1px solid black; */
}
.addnewprodline {
  display: flex;
}
.AddProdModal h3 {
  color: rgb(160, 182, 103);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.AddProdModal .searchresults li:hover {
  background-color: #ccccca;
}
.addproduct-form .form-group {
  width: 100%;
  flex-wrap: wrap;
}
.addproduct-form label {
  flex: 1;
  flex-basis: 20%;
}
.addproduct-form input {
  flex: 1;
  flex-basis: 45%;
  margin-right: 1rem;
}
.addproduct-form .presearchresults {
  flex: 1;
  flex-basis: 20%;
}
.addproduct-form .QSsearchresults-container {
  flex: 1;
  flex-basis: 45%;
  max-height: 20vh;
  overflow-y: auto;
  background-color: #e9e9e7;
  margin-right: 1rem;
  padding-right: 0;
}
.addproduct-form ul {
  padding-right: 0;
}
