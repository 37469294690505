.operationDetail {
  padding: 2rem;
  min-height: 600px;
  height: 55vh;
  padding: 1rem;
  margin: 1rem;
  /* border: 1px solid gray; */
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: solid 1px rgb(68, 65, 162); */
}
.opDetailHeader {
  width: 100%;
  /* background-color: red; */
  margin: 2rem;
}
.opDetailQSID {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}
.opDetailMIItem {
  display: flex;
  flex-direction: row;
  width: 300px;
}
.opDetailSupplyside {
  display: flex;
  flex-basis: 27%;
  min-width: 27%;
  flex-direction: column;
}
.opDetailDemandside {
  display: flex;
  flex-basis: 27%;
  min-width: 27%;
  flex-direction: column;
}
.opDetailItem {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.opDetailItem input {
  padding: 0.2rem 1rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  border: solid 1px rgb(183, 183, 183);
  width: 200px;
}
.opDItext {
  text-align: left;
  width: 200px;
  padding-left: 0.5rem;
}

@media screen and (max-width: 1440px) {
  .operationDetail {
    min-height: 400px;
  }
}
