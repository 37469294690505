.profitabilityreport {
  height: 75%;
  overflow: auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.profitabilityreport p {
  font-size: 0.8rem;
}

.profitabilityreportline {
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: -30;
  position: relative;
}
.profitabilityreportcolumn {
  display: flex;
  flex-basis: 20%;
}
.prfig {
  text-align: right;
  width: 100px;
  display: inline-block;
  padding-right: 3rem;
}
.prtotal {
  font-weight: bold;
  margin-top: 1rem;
}
.prheader {
  position: sticky;
  top: 0;
  font-weight: bold;
  border-bottom: 1px solid rgb(68, 65, 162);
  margin-bottom: 0.5rem;
  background-color: white;
  z-index: 2;
}
.prmonth {
  margin-bottom: 0.5rem;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  /* margin-top: 0.5rem; */
  top: 19px;
  font-size: 0.9rem;
  width: 100%;
  border-bottom: 1px solid rgb(68, 65, 162);
  z-index: -1;
  position: sticky;
  background-color: white;
}
.profitabilityreportline:nth-child(even) {
  background-color: rgb(245, 245, 245);
}
.profitabilityreportline:hover {
  background-color: #ddeaf9;
}
.prfiltermenu {
  position: absolute;
  background-color: white;
  z-index: 2000;
  min-height: 180px;
  min-width: 200px;
  max-height: 180px;
  top: 20px;
  border: 1px solid black;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  padding-right: 0;
}
.prcheckgroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.prcheckgroup label {
  margin-left: 0.5rem;
  font-weight: 400;
}
.prcheckgroup label:hover {
  cursor: pointer;
}
.ellipsis:hover {
  cursor: pointer;
}
.prcheckgroup:hover {
  background-color: rgb(222, 226, 231);
  cursor: pointer;
}
.prselect {
  border-bottom: dashed 1px rgb(190, 190, 190);
}
.prselect:hover {
  background-color: rgb(222, 226, 231);
  cursor: pointer;
}
.prdisplay-none {
  display: none !important;
}
.profreporttitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profreporttitle button {
  background-color: rgb(68, 65, 162);
  color: white;
  padding: 0.25rem 2rem;
  width: 150px;
  border-radius: 1rem;
  border: none;
}
.profreporttitle button:hover {
  cursor: pointer;
  background-color: rgb(96, 93, 184);
}
