.opnotes {
  max-height: 65%;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.opnoteinputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding-top: 1rem;
}
.opnoteinput {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  width: 82%;
  height: 80px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.opnotepost {
  padding: 0.4rem 1rem;
  background-color: rgb(68, 65, 162);
  border-radius: 0.5rem;
  border: none;
  color: white;
}
.opnotepost:hover {
  background-color: rgb(125, 123, 200);
  cursor: pointer;
}
.opnotegroup {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  width: 70%;
  align-self: flex-end;
}
.otherusermsg {
  align-self: flex-start;
}
.otherusermsg .opnote {
  background-color: rgb(227, 226, 235);
  color: rgb(68, 65, 162);
  border: solid 1px rgb(68, 65, 162);
}
.opnote {
  width: 100%;
  background-color: rgb(68, 65, 162);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.opnotemetadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}
.operationNotes {
  border: solid 1px rgb(68, 65, 162);
  border-radius: 0.7rem;
  padding: 1rem;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 38%;
  min-width: 38%;
}
