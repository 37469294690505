body {
  color: #333333;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  line-height: 18px;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
dl,
dt,
dd,
ol,
ul,
li,
table,
tr,
td,
form,
object,
embed,
article,
aside,
canvas,
command,
details,
fieldset,
figcaption,
figure,
footer,
group,
header,
hgroup,
legend,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
audio,
video {
  border: 0 none;
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none outside none;
}

a {
  text-decoration: none;
  color: #0088cc;
  cursor: pointer;
}

.fl {
  float: left;
  width: 49%;
}

.fr {
  float: right;
  width: 49%;
  text-align: right;
}

.container {
  /*margin: 0 auto;*/
  /*width: 1080px;*/
  margin: 20px;
}

#table-container {
  margin-top: 10px;
}

/* Table Sorter */
.tablesorter {
  background-color: #fff;
  margin: 10px 0 15px;
  text-align: left;
  border-spacing: 0;
  border: #ddd 1px solid;
  border-width: 1px 0 0 1px;
  font: 0.75rem Arial, Sans-serif; /*12px/18px*/
  border-collapse: separate;
}

.tablesorter th,
.tablesorter td {
  border: #ddd 1px solid;
  border-width: 0 1px 1px 0;
  overflow: hidden;
  white-space: nowrap;
}

/* header */
.tablesorter th,
.tablesorter thead td {
  font: 12px/18px Arial, Sans-serif;
  color: #555;
  background-color: #f5f5f5;
  padding: 1px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
}

.tablesorter tbody td,
.tablesorter tfoot th,
.tablesorter tfoot td {
  padding: 0px 4px;
  vertical-align: top;
}

.tablesorter .header,
.tablesorter .header-extra,
.tablesorter .tablesorter-header {
  padding: 4px 4px;
  white-space: normal;
  cursor: pointer;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid transparent;
  background: #f5f5f5 url(../img/sort.gif) no-repeat right 5px center;
}

.tablesorter .header-extra {
  padding: 2px 4px;
  border-bottom: 1px solid #ddd;
  background-image: none;
}

.tablesorter th.headerSortAsc,
.tablesorter th.tablesorter-headerSortAsc {
  color: #333;
  background: #f5f5f5 url(../img/asc.gif) no-repeat right 5px center;
}

.tablesorter th.headerSortDesc,
.tablesorter th.tablesorter-headerSortDesc {
  color: #333;
  background: #f5f5f5 url(../img/desc.gif) no-repeat right 5px center;
}

.tablesorter td {
  color: #333;
}

.tablesorter tbody > tr:hover > td {
  color: #000;
  background: #ddeaf9;
  /* font-weight: bold; */
}
/* End Table Sorter */

#tools {
  margin: 5px 0;
  padding: 5px 5px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: #000;
  font-size: 13px;
}

select {
  margin-right: 10px;
}

code {
  background-color: #eeeeee;
}

h2 {
  margin-bottom: 10px;
}
.tablesorter thead td .sales-filter {
  margin-right: 10px;
}
