#navbarcontainer {
  width: 100%;
  /* background-color: white; */
  background-color: rgb(68, 65, 162);
  position: fixed;
  display: flex;
  z-index: 21;
  top: 6vh;
  border-bottom: solid rgb(68, 65, 162) 1px;

  /* padding: 0rem 0rem; */
}
#navbar {
  width: 60%;
  justify-content: space-between;
  display: flex;
}
#navbar li {
  list-style-type: none;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  flex: 1;
  text-align: center;
}
#navbar li:hover {
}
#navbar a {
  width: 100%;
  text-decoration: none;
  display: block;
  padding: 0.3rem 0;
  color: white;
}
#navbar a:hover {
  cursor: pointer;
  background: rgb(123, 120, 219);
  color: white;
}
.navbaractive {
  background-color: rgb(223, 223, 223);
  background-color: rgb(160, 182, 103);
  background-color: rgb(123, 120, 219);
  color: white;
  /* border-bottom: dashed 1px black; */
}
