#profitability {
  overflow: hidden;
  padding-bottom: 5rem;
}
#profitability h3 {
  padding-bottom: 0.5rem;
}
.periodfilter {
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  align-items: baseline;
}
.periodfilterbuttons {
  display: flex;
  flex-direction: row;
  /* flex-basis: 50%; */
  width: 600px;
  justify-content: space-between;
}
.customdateinputs {
  display: flex;
  flex-direction: row;
  flex-basis: 40%;
  align-items: stretch;
  font-size: 0.8rem;
  margin: 0 0 0 1rem;
}
.customdateinputs label {
  margin: 0 0.3rem 0 1rem;
}
.customdateinputs button {
  border-radius: 1rem;
  border: none;
  padding: 0.3rem 1rem;
  margin-left: 1rem;
  color: white;
  background-color: rgb(68, 65, 162);
}
.customdateinputs button:hover {
  cursor: pointer;
  background-color: rgb(101, 98, 184);
}
.periodfilterbutton {
  border-radius: 1rem;
  border: none;
  padding: 0.3rem 1rem;
}
.periodfilterbutton:hover {
  cursor: pointer;
  background-color: rgb(169, 167, 218);
  color: white;
}
.active,
.active:hover {
  background-color: rgb(68, 65, 162);
  color: white;
}
.prsummarystats {
  display: flex;
  flex-direction: row;
  padding: 1.3rem;
  justify-content: space-between;
}
.prstatgroup {
  display: flex;
  flex-direction: column;
  flex-basis: 15%;
}
.prstatgroup h4 {
  font-size: 1rem;
}
.prstatgroup p {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.prgroupby {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  width: 770px;
  justify-content: space-between;
  padding: 0.5rem 0;
}
