* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.App {
  background-color: rgb(223, 223, 223);
  min-height: 100vh;
  height: auto;
  /* min-width: 1860px; */
}
