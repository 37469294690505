.Register {
  width: 100%;
  background-color: rgb(223, 223, 223);
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.RegisterForm {
  min-width: 30vw;
  margin: 0 auto;
  padding: 3.5rem 4rem;
  min-height: 55vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3em;
  /* border: rgb(160, 182, 103) 1px solid; */
  box-shadow: 3px 3px 30px rgb(170, 170, 170);
}
.Logo {
  width: 225px;
  height: 123px;
  margin: 2.5rem 0;
}
.RegisterForm h2 {
  font-family: "Roboto", sans-serif;
  /* padding-top: 3.5rem; */
  color: rgb(68, 65, 162);
  font-size: 2rem;
}
.RegisterForm input {
  width: 80%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-bottom: rgb(160, 182, 103) 1px solid;
}
.RegisterForm button {
  width: 60%;
  padding: 0.8rem;
  background-color: white;
  border: rgb(68, 65, 162) 1px solid;
  border-radius: 1.3rem;
  margin: 1rem 0;
  margin-bottom: 1rem;
}
.RegisterForm button:hover {
  background-color: rgb(68, 65, 162);
  color: white;
  cursor: pointer;
}
.errormessage {
  color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}
.successmessage {
  color: rgb(160, 182, 103);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}

.inputerror {
  color: red;
  margin-top: 0;
  padding-top: 0;
  font-size: 0.7rem;
  overflow-wrap: break-word;
  max-width: 17vw;
}
