.positionadd {
  overflow: auto;
  height: 100%;
}
.positionaddtitle {
  font-family: "Roboto", sans-serif;
  color: rgb(68, 65, 162);
}
#positionaddform {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
#positionaddform .form-group {
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
#positionaddform label {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  flex: 1;
  flex-basis: 20%;
  margin-top: 0.7rem;
}
#positionaddform input {
  margin-top: 0.6rem;
  padding: 0.3rem;
  /* border: none; */
  border: 1px solid rgb(160, 182, 103);
  margin-left: 0;
  flex: 4;
  flex-basis: 80%;
  font-family: "Roboto", sans-serif;
}
#positionaddform textarea {
  margin-top: 0.6rem;
  margin-left: 0;
  flex: 4;
  flex-basis: 80%;
  height: 20vh;
  resize: none;
  overflow-y: auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.95rem;
}
#positionaddform button {
  margin-top: 1.5rem;
  padding: 0.3rem 1.5rem;
  border: 2px solid rgb(68, 65, 162);
  background-color: transparent;
  border-radius: 3rem;
}

#positionaddform button:hover {
  background-color: rgb(68, 65, 162);
  color: white;
  cursor: pointer;
}
.posadderror {
  color: red;
  font-size: 0.85rem;
  margin-left: 20%;
  /* margin-bottom: 0.5rem; */
}
#positionaddform .presearchresults {
  /* width: 100px; */
  flex: 1;
  flex-basis: 10%;
}
#positionaddform .QSsearchresults-container {
  flex: 4;
  flex-basis: 40%;
}
