.budgetcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
  overflow-y: clip;
}
.budgetnav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  width: 130px;
  background-color: rgb(65, 64, 73);
  /* background-color: black; */
  color: white;
  padding: 8rem 2rem;
  height: 100vh;
}
.budgetgridcontainer {
  /* margin-left: 10%; */
  display: flex;
  flex-basis: 100%;
  display: inline-block;

  /* width: 150%; */
  position: relative;
  left: 130px;
  background-color: white;
  min-height: 100vh;
  margin-top: 2rem;
  padding-left: 2rem;
  height: auto;
  /* position: fixed; */
}
.addprodgroup {
  position: relative;
  margin-right: 1rem;
  /* padding-bottom: rem; */
  /* border-bottom: dashed 1px gray; */
}
.addprodbutton,
.cancelprodbutton {
  margin: 0.2rem 0;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
  border: none;
  color: white;
  background-color: rgb(68, 65, 162);
  position: relative;
}
.cancelprodbutton {
  background-color: white;
  border: solid 1px rgb(68, 65, 162);
  color: rgb(68, 65, 162);
}
.addprodbutton:hover {
  cursor: pointer;
  background-color: rgb(99, 95, 193);
}

.cancelprodbutton:hover {
  cursor: pointer;
  background-color: rgb(223, 223, 233);
}
.addprodpane {
  position: absolute;
  top: 25px;
  height: 300px;
  width: 230px;
  border: 1px solid rgb(68, 65, 162);
  border-radius: 0.75rem;
  background-color: white;
  z-index: 10;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.addprodpane ul {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  overflow-y: scroll;
  margin: 0.5rem 0;
  min-height: 75%;
}
.addprodcanceladd {
  display: flex;
  justify-content: space-between;
}
.addprodcanceladd button {
  display: flex;
  flex-basis: 45%;
}
.addprodrow {
  display: flex;
  flex-direction: row;
}
.addprodrow:hover,
.addprodrow input:hover,
.addprodrow label:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}
.addprodrow input {
  margin-right: 0.5rem;
}
.budgetfilterbuttons {
  padding-top: 0.5rem;
}
.budgetfilterbutton {
  margin: 0.2rem 0;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: rgb(68, 65, 162) solid 1px;
  color: rgb(68, 65, 162);
  background-color: white;
  width: 150px;
  margin-right: 0.5rem;
}
.budgetfilterbutton:hover {
  cursor: pointer;
  background-color: rgb(150, 148, 207);
  color: white;
}
.budgetfilterbutton:active {
  background-color: rgb(68, 65, 162);
  color: white;
  border: none;
}
.budgetfilterbuttonactive {
  background-color: rgb(68, 65, 162);
  color: white;
  border: none;
}
.pgroupfbtns {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: dashed 1px gray;
}
.pgroupfbtns p {
  padding-right: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.pnamefbtns {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: dashed 1px gray;
}
.pnamefbtns p {
  padding-right: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.pnamebutton {
  margin: 0.2rem 0;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: rgb(160, 182, 103) solid 1px;
  color: rgb(68, 65, 162);
  background-color: white;
  width: 150px;
  margin-right: 0.5rem;
}
.pnamebutton:hover {
  cursor: pointer;
  background-color: rgb(191, 214, 134);
  color: white;
}
.pnamebutton:active {
  background-color: rgb(160, 182, 103);
  color: white;
  border: none;
}
.pnamebuttonactive {
  background-color: rgb(160, 182, 103);
  color: white;
}
.bdgtpnametable {
  margin-top: 1rem;
  margin-right: 2rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.bdgtpnametable table {
  border-collapse: collapse;
}
.bdgtpnametable td {
  border: 1px solid gray;
  /* padding: 0.15rem 0; */
  margin: 0;
}
.bdgtpnametable td input {
  margin: 0;
  border: none;
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtpnametable .countrycol {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-width: 115px;
}
.bdgtpnametabletitle {
  display: flex;
  position: relative;
}
.bdgtpnametabletitle h3 {
  width: auto;
  margin-right: 1rem;
}
.budgettables {
  display: flex;
  flex-direction: column;
}
.bdgtdatacol {
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtpnametable .bdgtdatacol input {
  width: 60px;
  text-align: right;
  padding-right: 0;
}
.bdgtpnametable .bdgtregioncol {
  padding-left: 0.75rem;
  background-color: rgb(219, 219, 219);
}
.bdgtpnametable thead td {
  background-color: black;
  color: white;
}
.budgettablesandresponse {
  display: flex;
  flex-direction: column;
}
.bdgtresponsemsg {
  color: green;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: flex;
  width: 500px;
}
.bdgtcountrytotals {
  font-weight: 600;
  text-align: right;
  padding-right: 0.5rem;
  min-width: 70px;
}
.bdgtpnametable .bdgtregioncolttl {
  padding-left: 0.75rem;
  background-color: rgb(219, 219, 219);
  text-align: right;
  font-weight: 600;
  padding-right: 0.5rem;
}
.bdgttotal {
  background-color: black;
  color: white;
  padding-left: 0.75rem;
}
.bdgttotalqty {
  background-color: black;
  color: white;
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtctyrowadd {
  margin-right: 0.25rem;
}
.bdgtctyrowadd:hover {
  cursor: pointer;
}
.bdgtpnametable h3 {
  z-index: 0;
}
.bdgtregionrow {
  position: relative;
}
.bdgtselectcty {
  position: absolute;
  min-width: 230px;
  height: 300px;
  left: 150px;
  z-index: 10;
  top: 0px;
  border: 1px solid rgb(68, 65, 162);
  border-radius: 0.75rem;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bdgtselectallcty {
  position: absolute;
  min-width: 230px;
  height: 300px;
  z-index: 10;
  top: 18px;
  border: 1px solid rgb(68, 65, 162);
  border-radius: 0.75rem;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.showbdgtpane {
  display: inline-block;
}
.hidebdgtpane {
  display: none;
}
.bdgtselectctycty {
  margin: 0.5rem 0;
  max-height: 200px;
  overflow-y: scroll;
}
.addctyrow {
  display: flex;
  flex-direction: row;
}
.addctyrow:hover,
.addctyrow input:hover,
.addctyrow label:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}
.addctyrow label {
  margin-left: 0.25rem;
}
.bdgtctydelete {
  margin-left: 0.5rem;
  opacity: 0.3;
}
.bdgtctydeletebtn {
  border: none;
  padding: 0.05rem 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.5rem;
  margin-left: 0.25rem;
  background-color: rgb(68, 65, 162);
  color: white;
  font-size: 0.7rem;
}
.budgetprepdata {
  height: 54vh;
  display: flex;
  justify-content: space-between;
  /* overflow-y: scroll; */
}
.bdgtleftbottompanel {
  height: 54vh;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
}
.budgetbyprod {
  width: 580px;
  /* background-color: rgb(247, 247, 247); */
  border-left: 1px dashed rgb(68, 65, 162);
  padding: 1rem;
  padding-left: 2rem;

  /* height: 600px; */
  display: flex;
  flex-direction: column;
}
.budgetbyprod li {
  display: flex;
  font-size: 0.85rem;
}
.lyearfigures {
  margin-top: 1rem;
}
.lyearfigures h3 {
  font-family: "Roboto", sans-serif;
  font-size: 0.936rem;
  position: relative;
}
.lyearfigures table {
  border-collapse: collapse;
}
.lyearfigures table td {
  border: 1px solid gray;
}
.lyearhead {
  color: white;
  background-color: rgb(160, 182, 103);
}
.lyearregrow {
  background-color: rgb(219, 219, 219);
  /* font-weight: 600; */
}
.lyearregrowdata {
  background-color: rgb(219, 219, 219);
  text-align: right;
  font-size: 0.8rem;
  width: 60px;
  padding-right: 0.5rem;
  font-weight: 800;
}
.lyearcountrycol {
  padding-left: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  width: 110px;
}
.lyeardatah {
  text-align: right;
  font-size: 0.8rem;
  padding-right: 0.25rem;
}
.lyeardata {
  text-align: right;
  font-size: 0.8rem;
  width: 60px;
  padding-right: 0.5rem;
}
.lyearcountrycolttl {
  padding-left: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  width: 110px;
  background-color: black;
  color: white;
}
.lyeardatattl {
  text-align: right;
  font-size: 0.8rem;
  width: 60px;
  padding-right: 0.5rem;
  background-color: black;
  color: white;
}
.lyeartable {
  margin-bottom: 1rem;
}
.bdgttotals {
  border-bottom: 1px solid gray;
}
.bdgttotalsul {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 1rem;
  font-family: "Helvetica Neue", sans-serif;
}
.bdgttyearfig {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.bdgttyearname {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.bdgtlyearfigs {
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  width: 180px;
}
.bdgttitle {
  margin-right: 1rem;
}
.bdgttitles {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: solid 2px gray;
}
.bdgtpnametable .bdgtcolseparation {
  width: 15px;
  border: none;
  background-color: white;
}
.bdgtctyeconomics {
  width: 60px;
  text-align: right;
  padding-right: 0.5rem;
}
.bdgtpnametable .bdgtctyeconomics input {
  width: 60px;
  text-align: right;
  padding-right: 0;
  /* padding-right: 0.5rem; */
}
.pgroupfbtns .bdgtbtntitle {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}
.pnamefbtns .bdgtbtntitle {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
}
.budgetbyprod h4 {
  margin-bottom: 0.5rem;
}
.bdgtsummarybuttons1,
.bdgtsummarybuttons2,
.bdgtsummarybuttons {
  display: flex;
  /* padding: 0.5rem 0; */
  border-bottom: 1px gray dashed;
  width: 550px;
  align-items: center;
}
.bdgtsummarybuttons {
  margin-top: 1rem;
  border: none;
}
.bdgtsummarybuttons1 p,
.bdgtsummarybuttons2 p,
.bdgtsummarybuttons p {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.bdgtsummarybuttons1 button,
.bdgtsummarybuttons2 button,
.bdgtsummarybuttons button {
  width: 98px;
  /* margin-left: 0.5rem; */
  /* border-radius: 0.75rem; */
  padding: 0.25rem 0rem;
  background-color: white;
  border: none;
  font-size: 0.8rem;
  font-family: "Helvetica Neue", sans-serif;
}
.bdgtsummarybuttons1 button,
.bdgtsummarybuttons button {
  border-left: solid 1px rgb(68, 65, 162);
  border-right: solid 1px rgb(68, 65, 162);
}
.bdgtsummarybuttons button {
  padding: 0.25rem 0.5rem;
  width: 100px;
}
.bdgtsummarybuttons2 button {
  border-left: solid 1px rgb(160, 182, 103);
  border-right: solid 1px rgb(160, 182, 103);
}
.bdgtsummarybuttons1 .bdgtlvl1active {
  background-color: rgb(68, 65, 162);
  color: white;
}
.bdgtsummarybuttons2 .bdgtlvl2active {
  background-color: rgb(160, 182, 103);
  color: white;
}
.bdgtsummarybuttons .bdgtlvl3active {
  background-color: black;
  color: white;
}
.bdgtsummarybuttons1 button:hover {
  cursor: pointer;
  background-color: rgb(190, 189, 229);
}
.bdgtsummarybuttons2 button:hover {
  cursor: pointer;
  background-color: rgb(203, 215, 171);
}
.bdgtsummarybuttons button:hover {
  cursor: pointer;
  background-color: rgb(220, 220, 220);
  color: black;
}
.bdgtsummarybuttons1 button:active {
  background-color: rgb(68, 65, 162);
  color: white;
}
.bdgtsummarybuttons2 button:active {
  background-color: rgb(160, 182, 103);
  color: white;
}
.bdgtsummarybuttons button:active {
  background-color: black;
  color: white;
}
.bdgtsummarytable {
  margin-top: 1rem;
  width: 550px;
}
.bdgtsummarydata {
  height: 50vh;
  overflow-y: scroll;
  margin-right: 0;
  /* position: relative; */
}
.stblrow {
  display: flex;
  width: 100%;
  /* padding-left: 1rem; */
  font-family: "Helvetica Neue", sans-serif;
  font-size: 0.9rem;
}
.stblrow p {
  display: flex;
  /* flex-basis: 16%;
 */
  width: 88px;
}
.stblrow .stblcollarge {
  display: flex;
  /* flex-basis: 20%;
   */
  width: 76px;
}
.bsummaccordion_button {
  color: #444;
  cursor: pointer;
  padding: 2px 0;
  /* width: 500px; */
  text-align: left;
  border: none;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  /* position: sticky;
  top: 0; */
}
.bsummaccordion_button:before {
  display: inline-block;
  content: "";
  height: 5px;
  width: 5px;
  left: -5px;
  margin-right: 12px;
  margin-top: 5px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}
.bsummaccordion_button[aria-expanded="true"]::before,
.bsummaccordion_button[aria-selected="true"]::before {
  transform: rotate(45deg);
}
.bsummaccordion_button:hover {
  background-color: rgb(245, 245, 245);
}
.bdgtacc {
  border: none;
}
.stblheader {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid gray;
}
.stblfig {
  text-align: right;
  justify-content: right;
}
.bdgtaccpanel {
  padding: 0.5rem 0;
  border-top: 1px gray dashed;
  border-bottom: 1px gray dashed;
  margin-left: 15px;
}
.stblfooter {
  margin-bottom: 0.5rem;
  border-top: 1px solid gray;
  /* justify-content: right; */
}
.bdgtexport:hover {
  cursor: pointer;
}
.stblfooter .stblcollarge,
.stblheader .stblcollarge {
  width: 96px;
}
.stbltotal {
  font-weight: 600;
}
.budgetselectfilebutton {
  border: none;
  /* border-radius: 0.75rem; */
  padding: 0.25rem 1rem;
  margin-left: 25rem;
  background-color: black;
  color: white;
  margin-right: 1rem;
  font-size: 0.8rem;
}
.budgetselectfilebutton:hover {
  cursor: pointer;
  background-color: rgb(107, 107, 107);
}
.bdgtloadfileinput {
  min-width: 400px;
}
.bdgtloadbtn {
  border: none;
  border-radius: 0.75rem;
  padding: 0.25rem 1rem;
  margin-left: 1rem;
  background-color: rgb(161, 161, 161);
  color: white;
  z-index: 5;
}
.bdgtloadbtn:hover {
  cursor: pointer;
  background-color: rgb(97, 97, 97);
}
input[type="file"] {
  display: none;
}
.showloadbutton {
  display: inline;
}
.hideloadbutton {
  display: none;
}
.loadmsg {
  display: inline;
  margin-left: 1rem;
  color: rgb(24, 139, 24);
}
