.matchreport {
  margin-top: 2rem;
  overflow: scroll;
  height: 90%;
}
.matchsearch {
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 0.2rem 1rem;
  border-radius: 0.2rem;
  border: 1px solid rgb(192, 192, 192);
}

.matchaccordion_button {
  /* background-color: #f4f4f4; */
  color: #444;
  cursor: pointer;
  padding: 5px 20px;
  width: 100%;
  text-align: left;
  border: none;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.matchaccordion_button:hover {
  background-color: rgb(227, 237, 250);
}
.matchaccordion:nth-child(even) {
  background-color: #f4f4f4;
}
.matchaccordion_button:before {
  display: inline-block;
  content: "";
  height: 5px;
  width: 5px;
  margin-right: 12px;
  margin-top: 5px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}
.matchaccordion_button[aria-expanded="true"]::before,
.matchaccordion_button[aria-selected="true"]::before {
  transform: rotate(45deg);
}
.matchaccordion_button ul {
  display: flex;
  width: 100%;
}
.matchaccordion_button ul li {
  /* display: flex; */
  flex-basis: 15%;
  margin-right: 2rem;
}
.matchaccordion_button .prodli {
  flex-basis: 25%;
}
.matchpossales {
  display: flex;
  width: 50%;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.matchpossales li {
  display: flex;
  flex-direction: row;
  min-width: 100%;
}
.matchpossales li p {
  /* display: flex; */
  flex-basis: 30%;
}
.matchpossalesheader {
  border-bottom: 1px solid black;
}
.matchpossales .matchfig {
  text-align: right;
}
