#navigation {
  width: 100%;
  position: fixed;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: solid rgb(68, 65, 162) 1px;
  align-items: center;
  box-shadow: 0 8px 8px 0 rgba(160, 182, 103, 0.2);
  height: 6vh;
  padding: 0.3rem 3rem;
  font-family: "Roboto", sans-serif;
  /* background-color: white; */
  background-color: rgb(160, 182, 103);
  color: white;
}
#navigation .logoutButton {
  background-color: transparent;
  color: white;
  padding: 0.3rem 1.5rem;
  border: none;
  /* border: 2x solid rgb(68, 65, 162); */
  background-color: rgb(68, 65, 162);
  align-self: center;
  border-radius: 3rem;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
#navigation .logoutButton:hover {
  cursor: pointer;
  background-color: rgba(68, 65, 162, 0.75);
  color: white;
}
h2 {
  margin: 0;
}
.welcomemsg {
  display: flex;
  align-items: baseline;
  color: white;
}
.welcomemsg .cls-1 {
  fill: white;
  stroke: white;
  stroke-miterlimit: 0;
  stroke-width: 0;
}

.Logoheader {
  width: 2rem;
  height: 1.3rem;
  margin: 0 0.5rem;
  color: white;
}
.userwelcome {
  display: flex;
  font-size: 1rem;
}
