.AVBTable {
  margin-top: 1.5rem;
  margin-left: 1rem;
  width: 95%;
  overflow-x: scroll;
  /* margin: 0 auto; */
}
.AVBTableHeaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  font-weight: 800;
  border-bottom: 1px solid gray;
}
.AVBTableHeaders li {
  display: flex;
  flex-basis: 10%;
  /* min-width: 50px; */
}
.AVBTableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}
.AVBTableRow li {
  display: flex;
  flex-basis: 10%;
}
.AVBTableTotals li {
  display: flex;
  flex-basis: 10%;
}
.AVBTableRow .AVBTableFig,
.AVBTableHeaders .AVBTableFig,
.AVBTableTotals .AVBTableFig {
  justify-content: flex-end;
}
.AVBTableRow .AVBFirstCol,
.AVBTableHeaders .AVBFirstCol,
.AVBTableTotals .AVBFirstCol {
  min-width: 90px;
}
.AVBCharts {
  display: flex;
  flex-direction: row;
}
.AVBChart {
  display: flex;
  width: 49%;
}
.AVBGroupEnd {
  margin-right: 5rem;
}
.AVBTitleArea {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.groupbuttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.groupbuttons button {
  border-radius: 1rem;
  padding: 0.3rem 1.3rem;
  margin: 0 0.3rem;
  background-color: white;
  border: 2px solid rgb(211, 210, 225);
}
.groupbuttons button:hover {
  cursor: pointer;
  background-color: rgb(211, 210, 225);
  color: rgb(68, 65, 162);
}
.groupbuttons button:active {
  background-color: rgb(68, 65, 162);
  color: white;
}
.groupbuttons .activegroupbutton {
  background-color: rgb(68, 65, 162);
  color: white;
}
.groupbuttons .AVBReset {
  background-color: rgb(216, 55, 74);
  color: white;
  border: none;
}
.groupbuttons .AVBReset:hover {
  background-color: rgb(226, 99, 114);
  color: white;
}
.groupbuttons .AVBReset:active {
  background-color: rgb(216, 55, 74);
  color: white;
}
.groupbuttons .AVBBackbutton {
  background-color: rgb(160, 182, 103);
  border: none;
  color: white;
  margin-right: 2rem;
}
.groupbuttons .AVBBackbutton:hover {
  background-color: rgb(205, 217, 174);
  border: none;
  color: white;
}
.groupbuttons .AVBBackbutton:active {
  background-color: rgb(160, 182, 103);
  border: none;
  color: white;
}
.AVBTableTotals {
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}
.AVBTableTotals .AVBFirstCol {
  min-width: 90px;
}
