/* .searchfield {
  display: flex;
  flex-direction: column;
} */
.searchresults {
  position: relative;
  top: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  /* border: solid 1px gray; */

  /* display: table; */
}

.flexbreak {
  flex-basis: 100%;
  height: 0;
}
.presearchresults {
  /* min-width: 100%; */
  height: 2px;
  flex: 1;
  flex-basis: 22%;
}
.searchresults {
  /* display: block; */
  flex: 4;
  flex-basis: 70%;
}
.searchresults li:hover {
  background-color: #ddeaf9;
  cursor: pointer;
  /* color: white; */
}
