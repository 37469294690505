.salesQS {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* border: solid black 1px; */
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.salesQS-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.salesQS-form section {
  padding: 1rem;
}
.salesQS-form fieldset {
  border: solid 1px black;
  border-style: groove;
  padding: 0.5rem;
  margin-bottom: 1rem;
  /* background-color: rgba(160, 182, 103, 0.2); */
}
.salesQS-form legend {
  /* background-color: white; */
  font-weight: 900;
  color: rgb(68, 65, 162);
  font-size: 1rem;
}
.salesQS-form input {
  border: none;
  border-bottom: black solid 1px;
  line-height: 1.3rem;
  padding-left: 5px;
}
.salesQS-form .form-group {
  width: 100%;
  padding-bottom: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.salesQS-form .form-group label {
  /* flex: 1; */
  min-width: 120px;
  flex-basis: 32%;
}
.salesQS-form .form-group input {
  /* flex: 1; */
  flex-basis: 42%;
  min-width: 42%;
}
#salesQS-1 .form-group input {
  flex-basis: 60%;
  min-width: 60%;
}
#salesQS-1 .form-group label {
  min-width: 70px;
  flex-basis: 25%;
}
#salesQS-1 {
  /* border: solid 1px black; */
  /* flex: 1; */
  flex-basis: 25%;
  min-height: 100%;
}
#salesQS-2 {
  /* border: solid 1px black; */
  /* flex: 1; */
  /* flex-basis: 22%;
   */
  min-height: 100%;
  flex-basis: 25%;
}
#salesQS-3 {
  /* border: solid 1px black; */
  /* flex: 1; */
  /* display: flex; */
  /* flex-basis: 50%; */
  flex-basis: 50%;
  min-height: 100%;
}
#salesQS-3-fieldset {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 86%;
}
#salesQS-3-col1 {
  /* border: solid 1px black; */
  flex: 1;
  flex-basis: 50%;
}
#salesQS-3-col2 {
  /* border: solid 1px black; */
  flex: 1;
  flex-basis: 50%;
}
.salesQS-form button {
  width: 32%;
  margin-right: 0.5rem;
  padding: 0.3rem 1.5rem;
  border: 2px solid rgb(68, 65, 162);
  background-color: transparent;
  border-radius: 3rem;
  margin-top: 0.5rem;
}
.salesQS-form button:hover {
  background-color: rgb(68, 65, 162);
  color: white;
}
.QSfig {
  text-align: right;
  padding-right: 5px;
}
.QSfig2 {
  text-align: right;
  padding-right: 15px;
}
#shipmenttyperadio input {
  min-width: 0;
  margin-left: 0;
  flex-basis: 5%;
}
#shipmenttyperadio label {
  flex-basis: 15%;
}
.WGPSelect,
.USWGPSelect {
  margin-left: 1rem;
  flex: 1;
  flex-basis: 20%;

  appearance: none;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  background-color: white;
}
.WGPSelect option,
.USWGPSelect option {
  overflow: hidden;
  white-space: nowrap;
  background-color: white;
}
.saletype-group {
  display: flex;
  flex-direction: row;
}
.saletype-group label {
  flex: 1;
}
.salesQS-form .soldcheckbox {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
}
.salesQS-form .soldcheckbox label {
  margin-left: 0.5rem;
}
#QSbuttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.salesQStitleline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.salesQSnavbuttons {
  display: flex;
  flex-direction: row;
}
.salesQSnavbuttons button {
  border-radius: 1rem;
  margin: 0 1rem;
  width: 60px;
  border: none;
}
.salesQSnavbuttons button:hover {
  cursor: pointer;
  background-color: rgb(68, 65, 162);
  color: white;
}
#exchangerate label {
  display: flex;
  flex: 1 20%;
}

#exchangerate input {
  display: flex;
  flex: 1 30%;
}
#exchangerate button {
  display: flex;
  flex: 1 40%;
  margin-top: 1rem;
  margin-right: 0;
  margin-left: 0;
  background-color: rgb(68, 65, 162);
  color: white;
  border: none;
}
#exchangerate button:hover {
  cursor: pointer;
  background-color: rgb(123, 120, 219);
  /* border: none; */
}
.QSindexbox {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.QSIDerror {
  color: red;
  margin-left: 90px;
}
.editmode {
  background-color: rgb(246, 246, 246) !important;
}
.unlockicon {
  height: 20px;
  margin-left: 5px;
}
.lockicon {
  height: 20px;
  margin-left: 5px;
}
.custom-ui {
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  min-width: 550px;
  min-height: 200px;
  text-align: center;
}
.custom-ui h1 {
  color: rgb(68, 65, 162);
  margin-bottom: 2rem;
}
.confirmmsg {
  max-width: 450px;
  margin-bottom: 2rem;
}
.custom-ui button {
  padding: 0.3rem 1.5rem;
  border: solid 2px rgb(68, 65, 162);
  background-color: white;
  border-radius: 3rem;
  margin: 0 1rem;
}
.custom-ui button:hover {
  cursor: pointer;
  background-color: rgba(68, 65, 162, 0.75);
  color: white;
}
.custom-ui ul {
  margin-bottom: 1.5rem;
}
.editsline {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.editsheader {
  font-weight: bold;
}
.edititem {
  min-width: 130px;
  text-align: left;
}
.editfig {
  text-align: right;
  min-width: 130px;
}
.saleboxes {
  display: flex;
  flex-direction: row;
}
.bottompopupcalculator {
  position: absolute;
  /* top: 10px; */
  width: 380px;
  background-color: rgb(230, 230, 230);
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  z-index: 1;
}
.bottompopupcalculator .form-group label {
  min-width: 150px;
}
.bottompopupcalculator::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid rgb(230, 230, 230);
  border-right: 10px solid transparent;
  border-bottom: 15px solid rgb(230, 230, 230);
  border-top: 10px solid transparent;
  left: 120px;
  top: -12px;
}
.bottompopupcalculatorlg {
  position: absolute;
  display: flex;
  /* top: 10px; */
  width: 680px;
  background-color: rgb(230, 230, 230);
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  z-index: 1;
}
.bottompopupcalculatorlg-1 {
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
}
.bottompopupcalculatorlg-1 {
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
}
.bottompopupcalculatorlg .form-group label {
  min-width: 150px;
}
.bottompopupcalculator ul {
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.saveQSbutton:hover {
  cursor: pointer;
}
#QSbuttons span {
  padding-right: 2rem;
  color: green;
}
.storagefields .form-group label {
  min-width: 142px;
}
.storagefields .form-group input {
  flex-basis: 36%;
  min-width: 36%;
  margin-left: 4px;
}
.storagefields .form-group select {
  flex-basis: 48% !important;
  min-width: 33%;
}
.form-group {
  justify-content: space-between;
}
.salesQS-form button:focus {
  background-color: rgb(68, 65, 162);
  color: white;
}
